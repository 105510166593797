<template>
  <v-app>
    <div class="main" style="background-color: #f3f6f9">
      <div>
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex mb-9">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">

                <v-avatar
                    class="profile"
                    color="grey"
                    size="100"
                    tile
                >
                  <v-img :src=" user.image_path['thumb']" v-if="user.image_path"></v-img>
                  <img
                      v-else
                      :alt="user.full_name"
                      :src="`https://ui-avatars.com/api/?name=${user.full_name}&background=random`"
                  >
                </v-avatar>
                <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                  <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                       href="#">{{ user.full_name }}</a>
                    <a href="#">
                      <i class="flaticon2-correct text-success font-size-h5"></i>
                    </a>
                  </div>
                </div>
                <!--end::Title-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{ user.email }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-user-outline-symbol mr-2 font-size-lg"></i>{{ user.gender_text }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-phone mr-2 font-size-lg"></i>{{ user.phone || user.mobile }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#" v-if="user.formatted_date_of_birth">
                        <i class="flaticon2-calendar mr-2 font-size-lg"></i>{{ user.formatted_date_of_birth }}</a>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
                    <!--                    <div class="flex-grow-1">-->
                    <!--                      &lt;!&ndash;begin::Content&ndash;&gt;-->
                    <!--                      <div class="d-flex flex-wrap justify-content-between mt-1">-->
                    <!--                        &lt;!&ndash;                                <div class="d-flex flex-column flex-grow-1 pr-8">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                                    <span class="font-weight-bold text-dark-50">I distinguish three main text objectives could be merely to inform people.</span>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                                    <span class="font-weight-bold text-dark-50">A second could be persuade people.You want people to bay objective</span>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                                </div>&ndash;&gt;-->
                    <!--                        <div class="d-flex align-items-center w-25 flex-fill float-right  ">-->
                    <!--                          <span class="font-weight-bold text-dark-75">Progress</span>-->
                    <!--                          <div class="progress progress-xs mx-3 w-100">-->
                    <!--                            <div aria-valuemax="100" aria-valuemin="0"-->
                    <!--                                 aria-valuenow="50" class="progress-bar bg-success"-->
                    <!--                                 role="progressbar"-->
                    <!--                                 style="width: 63%;"></div>-->
                    <!--                          </div>-->
                    <!--                          <span class="font-weight-bolder text-dark">78%</span>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash;end::Content&ndash;&gt;-->
                    <!--                    </div>-->
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
            <div class="separator separator-solid"></div>

          </div>
        </div>
      </div>
      <v-row>
        <v-col cols="3" lg="2" md="3" sm="3">
          <v-tabs
              v-model="tab"
              vertical>
            <v-tab v-if="checkIsAccessible('agent', 'show')">
              <v-icon left>
                  fa fa-info-circle
              </v-icon>
              General information
            </v-tab>

            <v-tab v-if="checkIsAccessible('user_address', 'show')">
              <v-icon left>
                fas fa-address-book
              </v-icon>
              Address
            </v-tab>

            <v-tab v-if="checkIsAccessible('user', 'list') && user.is_agent">
              <v-icon left>
                fas fa-file
              </v-icon>
              Documents
            </v-tab>

            <v-tab v-if="checkIsAccessible('user', 'list') && user.is_agent">
              <v-icon left>
                fas fa-users
              </v-icon>
              Referred candidates
            </v-tab>

            <v-tab v-if="checkIsAccessible('application', 'list') && user.is_agent">
              <v-icon left>
                fas fa-passport
              </v-icon>
              Applications
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-payment', 'list') && user.is_agent">
              <v-icon left>
                fas fa-file-pdf
              </v-icon>
              Payment & Invoice
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-setting', 'list') && user.is_agent">
              <v-icon left>
                fas fa-dollar-sign
              </v-icon>
              Payment information
            </v-tab>

            <v-tab v-if="checkIsAccessible('agent-limit-institution', 'list') && user.is_agent">
              <v-icon left>
                fas fa-building
              </v-icon>
              Limit institutions
            </v-tab>

            <v-tab v-if="checkIsAccessible('agent-commission', 'list') && user.is_agent">
              <v-icon left>
                fas fa-percent
              </v-icon>
              Commission information
            </v-tab>

            <v-tab v-if="checkIsAccessible('application-discussion', 'list') && user.is_agent">
              <v-icon left>
                fas fa-tags
              </v-icon>
              Support
            </v-tab>

            <v-tab v-if="checkIsAccessible('user-email', 'list') && user.is_agent">
              <v-icon left>
                fas fa-envelope-open-text
              </v-icon>
              Email History
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="9" lg="9" md="9" sm="9">
          <v-tabs-items v-model="tab">
            <v-tab-item v-if="checkIsAccessible('agent', 'show')">
              <detail
                  :user_id="user.id"
                  :user="user"
                  :user_setting="user_setting"
                  @setting-saved="getUserSetting"
                  @user-saved="getUser"
              ></detail>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user_address', 'show')">
              <user-address :user_id="user.id"></user-address>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user', 'list') && user.is_agent">
              <document v-if="user_id" :user_setting="user_setting" @refresh="getUserSetting"></document>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user', 'list') && user.is_agent">
              <student-list v-if="user_id" :user_id="user_id"></student-list>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('application', 'list') && user.is_agent">
              <application v-if="user_id" :user_id="user_id"></application>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-payment', 'list') && user.is_agent">
              <payment-list v-if="user_id" :agent_id="user_id"></payment-list>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-setting', 'list') && user.is_agent">
              <bank-detail v-if="user_id" :user_id="user_id" :user_setting="user_setting"></bank-detail>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('agent-limit-institution', 'list') && user.is_agent">
              <agent-limit-institution></agent-limit-institution>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('agent-limit-institution', 'list') && user.is_agent">
              <agent-commission></agent-commission>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('application-discussion', 'list') && user.is_agent">
              <support v-if="user_id" :agent_id="user_id"></support>
            </v-tab-item>

            <v-tab-item v-if="checkIsAccessible('user-email', 'show') && user.is_agent">
              <email-activity v-if="user_id" :user_id="user_id" :user_setting="user_setting"></email-activity>
            </v-tab-item>

          </v-tabs-items>
        </v-col>

      </v-row>
    </div>
  </v-app>
</template>

<script>
import UserService from "@/services/user/UserService";
import StudentList from "./components/StudentList";
import Application from "./components/Application"
import UserDetails from "@/view/pages/view/user/student/profile/components/UserDetails";
import Detail from "@/view/pages/view/user/agent/components/UserDetail"
import UserSettingService from "@/services/user/setting/UserSettingService";
import Invoice from "./components/Invoice";
import PaymentList from "@/view/pages/view/user/student/profile/components/payment/PaymentList"
import EmailActivity from "@/view/pages/view/user/student/profile/components/EmailActivity";
import Document from "./components/Document";
import Support from "@/view/pages/view/user/agent/components/Support";
import AgentLimitInstitution from "@/view/pages/view/user/agent/components/limit-institution/AgentLimitInstitution";
import BankDetail from "@/view/pages/view/user/agent/components/BankDetail";
import AgentCommission from "@/view/pages/view/user/agent/components/commission/AgentCommission";
import UserAddress from "@/view/pages/view/user/student/profile/components/UserAddress.vue";

const userService = new UserService();
const userSettingService = new UserSettingService();

export default {
  name: "AgentProfile",
  components: {
    UserAddress,
    AgentCommission,
    BankDetail,
    AgentLimitInstitution,
    Support,
    Document,
    UserDetails,
    StudentList,
    Application,
    Detail,
    Invoice,
    PaymentList,
    EmailActivity
  },
  data() {
    return {
      user: {},
      user_setting: {},
      tab: 0,
    }
  }, computed: {
    user_id() {
      return this.$route.params.id
    }
  }, mounted() {
    this.getUser()
  }, methods: {
    getUser() {
      userService.show(this.user_id).then(response => {
        this.user = response.data.user
        this.getUserSetting();
      })
    },
    getUserSetting() {
      userSettingService
          .index(this.user_id)
          .then(response => {
            this.user_setting = response.data.user_setting;
            if (this.user_setting == null) {
              this.user_setting = {
                user: null,
                country_of_citizen_id: '',
                first_language: '',
                passport_number: '',
                passport_expiry_date: '',
                is_married: '',
                refused_visa: false,
                valid_study_permit: '',
                details: '',
                commission_percentage: '',
              }
            }
          });
    }
  }
}
</script>
