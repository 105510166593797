<template>
  <!--begin::Content-->
  <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
    <v-skeleton-loader
        type="table-thead"
        v-if="loading"
    >
    </v-skeleton-loader>

    <v-skeleton-loader
        v-if="loading"
        type="table-row-divider@25"
    >
    </v-skeleton-loader>
    <!--begin::Card-->
    <div class="card">
      <div class="card-header align-items-center py-5 gap-5">
        <!--begin::Actions-->
        <div class="d-flex justify-content-between">
          <div>
            <!--begin::Back-->
            <a @click="back" class="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Back">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
              <span class="svg-icon svg-icon-1 m-0">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
																	<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
																</svg>
															</span>
              <!--end::Svg Icon-->
            </a>
            <!--end::Back-->
            <v-icon @click="markAsReadAndUnRead()" class="mx-4" small :color="discussion.is_read_admin ? 'primary' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as Read/not read">fas fa-book</v-icon>
            <v-icon @click="makeStarred()" class="mx-4"  small :color="discussion.is_starred ? 'primary' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as Starred/not starred">fas fa-star</v-icon>
            <v-icon @click="makePinned()" class="mx-4"  small :color="discussion.is_pinned ? 'primary' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as Pinned/not pinned">fas fa-bookmark</v-icon>
          </div>
          <!--begin::Pagination-->
          <div class="d-flex align-items-center">

            <!--begin::Settings menu-->
            <div>
              <v-btn
                  class="text-right"
                  icon
                  rounded
                  x-large
                  color="red"
                  @click="closeTicket"
              ><v-icon>mdi-close-circle</v-icon></v-btn>
            </div>
            <!--begin::Settings menu-->
          </div>
          <!--end::Pagination-->
        </div>
        <!--end::Actions-->

      </div>
      <div class="card-body">
        <!--begin::Title-->
        <div class="d-flex flex-wrap gap-2 justify-content-between mb-8">
            <!--begin::Heading-->
            <h4 class="fw-semibold me-3 my-1">{{ discussion.name }}  <br />
              Category: {{ discussion.category_name }}</h4>
            <!--begin::Heading-->
            <!--begin::Badges-->
            <span class="my-1 me-2">
               <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">User: </span> {{ discussion.user ? discussion.user : 'N/A' }} <br>
                <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">Agent: </span> {{ discussion.agent ? discussion.agent : 'N/A'}}
            </span>
            <span class="my-1">
                <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">Institution: </span> {{ discussion.application_institution ? discussion.application_institution : 'N/A' }} <br>
                <span class="
                    text-info
                    font-weight-bolder font-size-md
                  ">Academic Program: </span> {{ discussion.application_academic_program ? discussion.application_academic_program : 'N/A' }}
            </span>
            <span class="my-1">
                Created At:
                <span class="flex-grow-1 ml-5 mt-4 mr-2 text-muted">
                  {{ discussion.formatted_created_at }}
                </span>
                <br>
                Replied At:
                <span class="flex-grow-1 ml-5 mt-4 mr-2 text-muted">
                  {{ discussion.formatted_updated_at }}
                </span>
            </span>
            <span class="my-1">
                <span class="flex-grow-1 text-muted">
                  {{ discussion.readable_created_at }}
                </span>
                <br>
                <span class="badge badge-primary" v-if="discussion.discussion_status != 'Closed'">
                  {{ discussion.discussion_status }}
                </span>
                <span class="badge badge-danger" v-else>
                  {{ discussion.discussion_status }}
                </span>
            </span>
            <!--end::Badges-->

        </div>
        <!--end::Title-->

        <v-row
            class="mx-1 my-3"
            v-if="discussion && discussion.attachments != null"
        >
          <v-col
              v-for="(attachment, index) in discussion.attachments"
              :key="index"
              class="d-flex child-flex"
              cols="1"
          >
            <a
                v-if="attachment.file_path && attachment.file_type == 'image'"
                :href="attachment.file_path.real"
                target="_blank"
            >
              <v-img
                  :lazy-src="attachment.file_path.real"
                  :src="attachment.file_path.real"
                  aspect-ratio="1"
                  max-width="100"
                  max-height="100"
              >
              </v-img>
            </a>
            <a
                v-if="attachment.file_path && attachment.file_type == 'pdf'"
                :href="attachment.file_path.real"
                target="_blank"
            >
              <v-img
                  :lazy-src="require('@/assets/media/pdf.png')"
                  :src="require('@/assets/media/pdf.png')"
                  aspect-ratio="1"
              >
              </v-img>
            </a>
            <a
                v-if="attachment.file_path && attachment.file_type == 'doc'"
                :href="attachment.file_path.real"
                target="_blank"
            >
              <v-img
                  :lazy-src="require('@/assets/media/document.png')"
                  :src="require('@/assets/media/document.png')"
                  aspect-ratio="1"
                  max-width="100"
                  max-height="100"
              >
              </v-img>
            </a>
            <a
                v-if="attachment.attachment_url"
                :href="attachment.attachment_url"
                target="_blank"
            >
              Attachment Url {{ index + 1 }}
            </a>
          </v-col>
        </v-row>

        <v-row
            class="ml-2 mb-2"
        >
          <v-col
              cols="12"
          >
            <span class="font-weight-bold">Description: </span> <p v-html="discussion.description"></p>
          </v-col>
          <v-col
              cols="12"
              v-if="discussion.is_closed"
          >
              <b>Closed Remarks: </b>
              <br>
              <div class="m-5 py-5" v-html="discussion.description"></div>
          </v-col>
        </v-row>
        <!--begin::Message accordion-->
        <div class="my-5" v-for="(thread, index) in discussion_threads"  :key="index">
          <!--begin::Message header-->
          <div class="d-flex flex-wrap gap-2 flex-stack justify-content-between cursor-pointer" >
            <!--begin::Author-->
            <div class="d-flex align-items-center" v-b-toggle="`collapse-${index}`">
              <!--begin::Avatar-->
              <img v-if="thread.admin_user" style="height: 50px;width: 50px" class="me-2" src="/media/logos/applycan_white.jpg">
              <div v-if="thread.agent_symbol_label" class="symbol symbol-50 me-4">
                <span class="symbol-label">{{ thread.agent_symbol_label }}</span>
              </div>
              <!--end::Avatar-->
              <div class="pe-5">
                <!--begin::Author details-->
                <div class="d-flex align-items-center flex-wrap gap-1">
                  <a href="#" class="fw-bold text-dark text-hover-primary"> {{
                      thread.admin_user
                          ? thread.admin_user
                          : thread.user
                              ? thread.user
                              : thread.agent
                    }}</a>

                  <span class="ml-3 badge badge-info">
                          {{thread.replied_by_text ? thread.replied_by_text : ''}}
                       </span>
                  <span class="fw-semibold text-muted text-end me-3"><i class="text-success mx-5 fas fa-circle fa-xs"></i>
                        {{ thread.readable_created_at }}</span>
                </div>
                <!--end::Author details-->
                <!--begin::Preview message-->
                <!--                            <div class="text-muted fw-semibold mw-450px">Jornalists call this critical, introductory section the "Lede," and when bridge properly executed....</div>-->
                <!--end::Preview message-->
              </div>
            </div>
            <!--end::Author-->
            <!--begin::Actions-->

<!--            <div  class="d-flex align-items-center flex-wrap gap-2">-->
<!--              &lt;!&ndash;begin::Date&ndash;&gt;-->

<!--              &lt;!&ndash;end::Date&ndash;&gt;-->
<!--              <div class="d-flex">-->
<!--                <v-icon @click="makeStarred" class="mx-3" x-small :color="discussion.is_starred ? 'primary' : ''">fas fa-star</v-icon>-->
<!--                <v-icon @click="makePinned" class="mx-3" x-small :color="discussion.is_pinned ? 'primary' : ''">fas fa-bookmark</v-icon>-->
<!--              </div>-->
<!--            </div>-->
            <!--end::Actions-->
          </div>
          <!--end::Message header-->
          <!--begin::Message content-->
          <b-collapse :id="`collapse-${index}`" class="mt-2">
            <div class="m-5 py-5" v-html="thread.description"></div>
            <div class="m-5" v-if="thread.discussion_attachments.length > 0">
                  <span
                      v-for="(attachment, index) in thread.discussion_attachments"
                      :key="index"
                  >
                    <a
                        v-if="attachment.file_path"
                        :href="attachment.file_path.real"
                        class="
                        text-primary
                        font-weight-bolder
                        mb-1
                        mx-2
                        font-size-lg
                      "
                        target="_blank"
                    >
                      Attachment {{ index + 1 }}
                    </a>
                    <a
                        v-if="attachment.attachment_url"
                        :href="attachment.attachment_url"
                        class="
                        text-primary
                        font-weight-bolder
                        mb-1
                        mx-2
                        font-size-lg
                      "
                        target="_blank"
                    >
                      Attachment URL {{ index + 1 }}
                    </a>
                  </span>
            </div>
          </b-collapse>
          <!--end::Message content-->
        </div>
        <!--end::Message accordion-->

        <!--begin::Form-->
        <div class="m-5" v-if="!discussion.is_closed">
          <v-row>
            <v-col cols="12">
              <ckeditor
                  :config="editorConfig"
                  v-model="discussion_thread.description"
              >
              </ckeditor>
              <span
                  class="text-danger"
                  v-if="$v.discussion_thread.description.$error"
              >This Field is required</span
              >
            </v-col>

            <v-col cols="12">
              <v-select
                  label="Select Email Response"
                  v-model="selectEmailResponse"
                  :items="emailResponses"
                  @change="setDescription"
                  item-text="title"
                  item-value="description"
                  outlined
                  dense
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="4">
              <div class="text-left">
                <v-select
                    label="Attachment Type"
                    v-model="discussion_thread.attachmentType"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                >
                </v-select>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <div class="text-left">
                <v-file-input
                    v-if="discussion_thread.attachmentType == 'file'"
                    label="Attachment Files"
                    v-model="discussion_thread.files"
                    :error="$v.discussion_thread.files.$error"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    @change="previewImage"
                    multiple
                    clearable
                    counter
                    :show-size="1000"
                    outlined
                    dense
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 3"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 3"
                        class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ discussion_thread.files.length - 3 }} File(s)
                  </span>
                  </template>
                </v-file-input>
                <span class="text-danger" v-if="$v.discussion_thread.files.$error">This Field is required</span>
                <v-text-field
                    v-if="discussion_thread.attachmentType == 'url'"
                    label="Attachment Url"
                    v-model="discussion_thread.attachment_url"
                    :error="$v.discussion_thread.attachment_url.$error"
                    outlined
                    dense
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.discussion_thread.attachment_url.$error">This Field is required</span>
              </div>
            </v-col>
            <v-col cols="12" md="12" v-if="images.length > 0">
              <v-row>
                <v-col cols="12" md="3" v-for="(image, index) in images" :key="index">
                  <v-img
                      :lazy-src="image"
                      max-height="150"
                      max-width="150"
                      :src="image"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <div class="text-right">
                <v-btn
                    @click="_create"
                    :loading="isLoading"
                    class="btn btn-primary"
                    medium
                >Send</v-btn
                >
                <v-btn
                    class="ml-3 text-white"
                    @click="back"
                    color="red"
                >Back</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Card-->
    <close-discussion ref="close-discussion" @back="back"></close-discussion>
  </div>
  <!--end::Content-->
</template>
<script>
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";
import ApplicationDiscussionThreadService from "@/services/application/discussion/thread/ApplicationDiscussionThreadService";
import { required, requiredIf } from "vuelidate/lib/validators";
import CloseDiscussion from "@/view/pages/view/user/student/profile/components/application-discussion/CloseDiscussion";
import EmailResponseService from "@/services/cms/email-response/EmailResponseService";

const discussionThread = new ApplicationDiscussionThreadService();
const applicationDiscussion = new ApplicationDiscussionService();
const emailResponse = new EmailResponseService();
export default {
  components: { CloseDiscussion },
  name:"DiscussionView",
  props: ['discussionId', 'user_id'],
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: true,
      isLoading: false,
      selectEmailResponse: null,
      discussion: {},
      images: [],
      emailResponses: [],
      discussion_threads: [],
      discussion_thread: {
        discussion_id: "",
        discussion_thread_id: "",
        description: "",
        attachmentType: "",
        files: [],
        attachment_url: "",
      },
      types: [
        { name: "File", value: "file" },
        { name: "Url", value: "url" },
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
    };
  },
  validations: {
    discussion_thread: {
      description: { required },

      files: {
        required: requiredIf(function () {
          return this.discussion_thread.attachmentType == "file";
        }),
      },
      attachment_url: {
        required: requiredIf(function () {
          return this.discussion_thread.attachmentType == "url";
        }),
      },
    },
  },
  mounted() {
    this.getApplicationDiscussion();
    this.getApplicationDiscussionThreads();
    this.getAllEmailResponse();
  },
  computed: {
    formatted_date_time() {
      if (this.discussion && this.discussion.formatted_created_at) {
        let date = this.discussion.formatted_created_at.split(" ");
        return (
          date[0] +
          " " +
          date[1] +
          " " +
          date[2] +
          "," +
          date[3] +
          " " +
          date[4]
        );
      }
      return null;
    },
    no_of_files() {
      if (this.discussion_thread.files != null) {
        return this.discussion_thread.files.length;
      }
      return "0";
    },
  },
  methods: {
    back() {
        this.$emit('view-discussion');
    },
    getAllEmailResponse() {
      emailResponse.getAllActive().then(response => {
        this.emailResponses = response.data.emailResponses;
      }).catch(() => {});

    },
    setDescription() {
      if(this.selectEmailResponse) {
        this.discussion_thread.description = this.selectEmailResponse;
      }

    },
    makeStarred() {
      let data = {
        is_starred: '',
      }
      if(this.discussion.is_starred) {
        data.is_starred = 0;
      } else {
        data.is_starred = 1;
      }
      applicationDiscussion.update(this.discussion.id, data).then(() => {
        this.$snotify.success("Update Successfully");
        this.getApplicationDiscussion();
      }).then(() => {});
    },
    makePinned() {
      let data = {
        is_pinned: '',
      }
      if(this.discussion.is_pinned) {
        data.is_pinned = 0;
      } else {
        data.is_pinned = 1;
      }
      applicationDiscussion.update(this.discussion.id, data).then(() => {
        this.$snotify.success("Update Successfully");
        this.getApplicationDiscussion();
      }).then(() => {});
    },

    markAsReadAndUnRead() {
      let data = {
        is_read_admin: '',
      }
      if(this.discussion.is_read_admin) {
        data.is_read_admin = 0;
      } else {
        data.is_read_admin = 1;
      }
        applicationDiscussion.update(this.discussion.id, data).then(response =>{
          this.$snotify.success("Update Successfully");
          this.getApplicationDiscussion();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
    },
    closeTicket() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$refs["close-discussion"].editApplicationDiscussion(
              this.discussion
            );
          }
        },
      });
    },
    previewImage() {
      this.images = [];
      let length =  this.discussion_thread.files.length;
      if(length > 0) {
        for (let i = 0; i < length; i++) {
          const fileType = this.discussion_thread.files[i].type;
          if(i <= 3 && fileType.includes('image')) {
            this.images.push(URL.createObjectURL(this.discussion_thread.files[i]));
          }
          if(fileType.includes('officedocument')) {
            this.images.push(require('@/assets/media/document.png'));
          }
          if(fileType.includes('application/pdf')) {
            this.images.push(require('@/assets/media/pdf.png'));
          }
        }
      }
    },
    convertToFormData() {
      let formData = new FormData();
      this.discussion_thread.discussion_id = this.discussionId;
      if (
        this.discussion_thread.files &&
        this.discussion_thread.files.length > 1
      ) {
        for (const file in this.discussion_thread.files) {
          if (
            this.discussion_thread.files[file] != null &&
            this.discussion_thread.files[file] !== undefined
          ) {
            formData.append(
              `files[${file}]`,
              this.discussion_thread.files[file]
            );
          }
        }
      }
      if (this.discussion_thread.files.length == 1) {
        formData.append("files", this.discussion_thread.files[0]);
      }
      for (let key in this.discussion_thread) {
        if (this.discussion_thread[key] && key != 'files') {
          formData.append(key, this.discussion_thread[key]);
        }
      }
      return formData;
    },
    _create: function () {
      let formData = this.convertToFormData();
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.isLoading = true;
        discussionThread
          .create(formData)
          .then((response) => {
            this.isLoading = false;
            this.$snotify.success(
              "Application discussion thread created successfully"
            );
            this.getApplicationDiscussionThreads();
            this.resetForm();
          })
          .catch((err) => {
            this.isLoading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
      }
    },
    getApplicationDiscussion() {
      applicationDiscussion
        .show(this.discussionId)
        .then((response) => {
          this.discussion = response.data.applicationDiscussion;
          if(this.discussion == null) {
            this.$router.push({
              name: '404'
            })
          }
        })
        .catch((err) => {});
    },
    getApplicationDiscussionThreads() {
      discussionThread
        .getPaginateByTicket(this.discussionId)
        .then((response) => {
          this.discussion_threads = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.$v.$reset();
      this.images = [];
      this.discussion_thread = {
        discussion_id: "",
        discussion_thread_id: "",
        description: "",
        attachmentType: "",
        files: [],
        attachment_url: "",
      };
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panels {
  z-index: unset !important;
}
.v-expansion-panel-header__icon {
  display: none !important;
}
</style>
