<template>
  <v-dialog
      max-width="600"
      scrollable
      v-model="dialog"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Update Status</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">

            <v-col cols="12" v-if="remainingVisaStatuses.length === 0 && country_id && application_type">
              <v-select
                  outlined
                  dense
                  v-model="application_history.visa_status_id"
                  :items="visa_statuses"
                  item-text="title"
                  item-value="id"
                  :error="$v.application_history.visa_status_id.$error"
                  @change="getVisaApplicationStatusStates"
              >
                <template v-slot:label> Select Visa Application Status <span class="text-danger">*</span></template>
              </v-select>
              <span class="text-danger"
                    v-if="$v.application_history.visa_status_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.visa_status_id">{{ errors.visa_status_state_id[0] }}</span>
            </v-col>

            <v-col cols="12" v-if="remainingVisaStatuses.length > 0">
              <v-select
                  v-model="application_history.application_status_state_id"
                  :items="remainingVisaStatuses"
                  item-text="title"
                  item-value="id"
                  :error="$v.application_history.application_status_state_id.$error"
                  @change="changeStatusState"
                  return-object
                  outlined
                  dense
              >
                <template v-slot:label> Select Processing Status</template>
              </v-select>
              <span class="text-danger" v-if="$v.application_history.application_status_state_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.visa_status_id">{{ errors.visa_status_id[0] }}</span>
            </v-col>

            <v-col cols="12" v-else>
              <v-select
                  v-model="application_history.application_status_state_id"
                  :items="visaStateStatuses"
                  item-text="title"
                  item-value="id"
                  :error="$v.application_history.application_status_state_id.$error"
                  @change="changeStatusState"
                  return-object
                  outlined
                  dense
              >
                <template v-slot:label> Select Processing Status</template>
              </v-select>
              <span class="text-danger" v-if="$v.application_history.application_status_state_id.$error">This Field is required</span>
              <span class="text-danger" v-if="errors.visa_status_id">{{ errors.visa_status_id[0] }}</span>
            </v-col>

            <v-col cols="12">
              <label>Note</label>
              <v-textarea
                outlined
                dense
                v-model="application_history.note"
              ></v-textarea>
              <span class="text-danger" v-if="errors.note">{{ errors.note[0] }}</span>
            </v-col>
            <v-col>
              <v-file-input 
                v-model="application_history.attachment"
                outlined
                dense
                multiple
                ref="fileInput"
                accept="image/*, application/pdf"
                show-size
                prepend-icon=""
                prepend-inner-icon="mdi-file"
                placeholder="Attachments"
                label="Attachments"
                @change="checkFiles($event)"
              >
              </v-file-input>
              <span class="text-warning">** you can upload four attachment **</span>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                  v-model="application_history.send_email"
                  label="Send email upon any changes"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            class="btn btn-primary"
            medium
            :disabled="fileLength > 4"
            @click="saveApplicationHistory"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import {required, requiredIf} from "vuelidate/lib/validators";
import ApplicationService from "@/services/application/ApplicationService";


const visaApplicationService = new VisaApplicationStatusService();
const application = new ApplicationService();
const visaApplicationStatusService = new VisaApplicationStatusService();

export default {
  name: 'update-application-history',
  props: ['country_id', 'application_type'],
  validations: {
    application_history: {
      application_status_state_id: {required},
      visa_status_id: { required: requiredIf(function () {
          return this.remainingVisaStatuses. length == 0;
        })
      },
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fileLength: 0,
      application_history: {
        application_status_state_id: null,
        application_id: null,
        updated_by: null,
        send_email: false,
        status: '',
        note: '',
        attachment: null,
      },
      application: {},
      visa_statuses: [],
      visaStateStatuses: [],
      remainingVisaStatuses: [],
      errors: [],
    }
  },
  methods: {
    checkFiles(event) {
      this.fileLength = event.length;
      if(event.length > 4) {
        this.$snotify.error("Please upload four attachment only !!")
      }
    },
    updateApplicationHistory(application, visaApplicationStatusId) {
      this.resetForm();
      this.application = application;
      this.application_history.application_id = application.id;
      this.getRemainingVisaStatuses(this.application.id);
      this.dialog = true;
    },
    changeStatusState() {
      if(this.application_history.application_status_state_id) {
        let status_state = this.application_history.application_status_state_id;
        this.application_history.application_status_state_id = status_state.id;
        this.application_history.note = status_state.remarks;
      }
    },
    closeDialog() {
      this.resetForm();
      this.dialog = false;
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.application_history = {
        visa_status_id: null,
        application_id: null,
        updated_by: null,
        send_email: true,
        note: '',
        attachment: null,
      };
      this.application = '';
      this.application_history.application_id = null;
    },
    getVisaApplicationStatus() {
      if (!this.country_id)
        return
      if (!this.application_type)
        return
      this.visa_statuses = []
      visaApplicationStatusService.getByCountryAndApplicationType(this.country_id, this.application_type).then(response => {
        this.visa_statuses = response.data.applications_statuses;
      }).catch(error => {
        this.visa_statuses = []
      })
    },
    getVisaApplicationStatusStates() {
      if (!this.application_history.visa_status_id)
        return
      this.visaStateStatuses = []
      visaApplicationStatusService
          .getStatusByVisaStatusId(this.application_history.visa_status_id)
          .then((response) => {
            this.visaStateStatuses = response.data.data;
          })
          .catch(err => {
            this.visaStateStatuses = []
          });
    },
    getRemainingVisaStatuses(applicationId) {
      this.remainingVisaStatuses = []
      visaApplicationService
      .getRemainingVisaStatuses(applicationId)
      .then((response) => {
        this.remainingVisaStatuses = response.data.data;
        if(this.remainingVisaStatuses == 0) {
          this.getVisaApplicationStatus();
        }
      })
      .catch(err => {
        this.remainingVisaStatuses = []
      })
      .finally(() => {

      });
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.application_history) {
        if (key === "attachment" && this.application_history[key] != null && this.application_history[key] != undefined) {
          for(let file in this.application_history[key]){
            formData.append('attachment[]', this.application_history[key][file]);
          }
        }
        else {
          if(this.application_history[key]){
            formData.append(key,this.application_history[key]);
          }
        } 
      }
     
      return formData;
    },

    saveApplicationHistory() {
      this.application_history.updated_by = this.currentUser.id;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        let formData = this.convertToFormData();
        this.loading = true;
        application
            .createHistory(formData, this.application_history.application_id)
            .then((response) => {
              if(response.data.message) {
                this.$snotify.error(response.data.message);
              } else {
                this.$snotify.success('Status saved successfully');
                this.$emit('refresh', this.application_history.application_id);
                this.$emit('refresh-application');
                this.closeDialog();
              }
            })
            .catch(err => {
              this.loading = false;
              this.errors = err.errors;
              this.$snotify.error('Oops looks like something went wrong');
              this.closeDialog();
            })
            .finally(() => {
              this.loading = false;
            });
      }
    }
  }
}
</script>
