<template>
  <v-card>
    <v-card-title>
      <h4>
        Student List
        <span class="badge badge-primary">{{total}}</span>
      </h4>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <template v-if="loading">
            <v-skeleton-loader type="table-thead"></v-skeleton-loader>
            <v-skeleton-loader type="table-row-divider@25"></v-skeleton-loader>
          </template>
          <template v-if="!loading">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th><strong>Unique Identifier</strong></th>
                  <th><strong>Last Name</strong></th>
                  <th><strong>First Name</strong></th>
                  <th><strong>Email</strong></th>
                  <th><strong>Phone / Mobile</strong></th>
<!--                  <th><strong>Date Of Birth</strong></th>-->
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in users" :key="index">
                    <td>
                      <a @click="studentProfile(item.id)"
                         class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        <v-avatar
                            class="profile"
                            color="grey"
                            size="30"

                        >
                          <v-img v-if="item.image_path" :src="item.image_path['thumb']"></v-img>
                          <img
                              v-else
                              :alt="item.full_name"
                              :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${item.full_name}`"
                          >
                        </v-avatar>
                        &nbsp;&nbsp; <a @click="studentProfile(item.id)"
                                        class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.unique_identifier }}
                      </a>
                      </a>

                    </td>


                    <td class="text-left">
                      {{ item.last_name }}
                    </td>
                    <td>
                      <a @click="studentProfile(item.id)"
                         class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">{{
                          item.first_name
                        }}</a>
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                    <td>
                      {{ item.phone || item.mobile }}
                    </td>
<!--                    <td>-->
<!--                      {{ item.formatted_date_of_birth }}-->
<!--                    </td>-->
                    <td>
                    <span> <i class="fas"
                              :class="item.is_active?'text-success fa-check':'text-danger fa-ban'"></i></span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">


                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="studentProfile(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                <span class="navi-text">View Profile</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteUser(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="users.length == 0">
                    <td colspan="9" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <b-pagination
                v-if="users.length > 0"
                class="pull-right mt-7"
                @input="getAllUsers"
                :disabled="loading"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
            ></b-pagination>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UserService from "@/services/user/UserService";

const userService = new UserService();
export default {
  name: "student-list",
  props: ["user_id"],
  data() {
    return {
      loading: true,
      users: [],
      total: null,
      perPage: null,
      page: null,
      search: {
        is_student: 1,
        limit: 20
      },
    }
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      this.loading = true;
      this.search.added_by = this.user_id;
      userService.paginate(this.search, this.page).then(response => {
          this.users = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false
      }).catch(error => {
        this.loading = false;
        this.users = []
      })
    },
    studentProfile(id) {
      this.$router.push({
        name: 'student-profile',
        params: {user_id: id},
      })
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userService
                .delete(id)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Candidate deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>

<style scoped>

</style>