<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>Agent Limit institution</h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard</router-link>
                \ Agent Limit institution
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <div class="row">

              <v-col cols="12" md="10">
                <v-autocomplete
                    :items="institutions"
                    v-model="institutionIds"
                    :error="$v.institutionIds.$error"
                    label="Institutions"
                    item-text="title"
                    item-value="id"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    outlined
                    dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @click="selectAllInstitutions"
                    >
                      <v-list-item-action>
                        <v-icon :color="institutionIds.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="2">
                <v-switch  label="Is active" v-model="agent_limit_institution.is_active" outlined dense></v-switch>
              </v-col>

              <v-col cols="12">
                <v-btn
                    v-if="checkIsAccessible('agent-limit-institution', 'create') || checkIsAccessible('agent-limit-institution', 'edit')"
                     @click="createOrUpdate()"
                     class="btn btn-primary float-right"
                >
                  {{ agent_limit_institution.id ? "Update" : "Save" }}
                </v-btn>
              </v-col>
            </div>
          </div>

          <div class="table-responsive">
            <v-skeleton-loader
                v-if="loading"
                type="table-thead"
            >
            </v-skeleton-loader>

            <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
            >
            </v-skeleton-loader>
            <table class="table" v-if="!loading">
              <thead>
              <tr class="text-left">
                <th><strong>Institution</strong></th>
                <th class="pr-3 text-center"><strong>Action</strong></th>
              </tr>
              </thead>
              <tbody>
              <template>
                <tr v-if="agent_limit_institution && agent_limit_institution.id && agent_limit_institution.institution_ids.length > 0">
                  <td class="pl-5">
                   <ul>
                     <li v-for="(item, index) in agent_limit_institution.institutions" :key="index">
                       <span>
                          {{item.title}}
                       </span>
                     </li>
                   </ul>
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent', 'delete')">
                            <a class="navi-link" @click.prevent="deleteAgentLimitInstitution(agent_limit_institution.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="agent_limit_institution && !agent_limit_institution.id && agent_limit_institution.institution_ids.length == 0">
                  <td colspan="5" class="text-center"><strong>No Institution Found</strong></td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgentLimitInstitutionService from "@/services/user/agent/limit-institution/AgentLimitInstitutionService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import { required } from "vuelidate/lib/validators";

const agentLimitInstitution = new AgentLimitInstitutionService();
const institutionService = new InstitutionService();
export default {
  name: "AgentLimitInstitution",
  data() {
    return {
      loading: false,
      page: null,
      searchInstitution: null,
      institutionIds: [],
      agent_limit_institution: {
        agent_id:"",
        institution_ids: [],
        is_active: 1
      },
      institutions: [],
    }
  },
  validations: {
    institutionIds: {required},
  },
  mounted() {
    this.agent_limit_institution.agent_id = this.$route.params.id;
    this.getAgentLimitInstitution();
    this.getAllInstitutions();
  },
  computed: {
    selectAllInstitution () {
      return this.institutionIds.length === this.institutions.length
    },
    selectedInstitution () {
      return this.institutionIds.length > 0 && !this.selectAllInstitution
    },
    icon () {
      if (this.selectAllInstitution) return 'mdi-close-box'
      if (this.selectedInstitution) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    selectAllInstitutions () {
      this.$nextTick(() => {
        if (this.selectAllInstitution) {
          this.institutionIds = []
        } else {
          this.institutionIds = [];
          this.institutions.forEach(item => {
            this.institutionIds.push(item.id);
          });
        }
      })
    },

    getAllInstitutions() {
        institutionService.all().then((response) => {
            this.institutions = response.data.universities;
        }).catch(() => { });

    },

    getAgentLimitInstitution() {
      agentLimitInstitution.paginate({agent_id: this.agent_limit_institution.agent_id}, this.page).then(response => {
        if(response.data.data) {
          this.agent_limit_institution = response.data.data;
          this.institutionIds = this.agent_limit_institution.institution_ids;
        }
        else {
          this.institutionIds = [];
        }
      }).catch(() => {});
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.agent_limit_institution.institution_ids = this.institutionIds;
        if(this.agent_limit_institution.institution_ids.length > 0) {
          if(this.agent_limit_institution.id) {
            this.update();
          }else  {
            this.create();
          }
        }
      }

    },
    create() {
      agentLimitInstitution.create(this.agent_limit_institution).then(response => {
        this.$snotify.success("Created Successfully");
        this.getAgentLimitInstitution();
      }).catch(() => {});
    },
    update() {
      agentLimitInstitution.update(this.agent_limit_institution.id, this.agent_limit_institution).then(response => {
        this.$snotify.success("Created Successfully");
        this.getAgentLimitInstitution();
      }).catch(() => {});
    },
    deleteAgentLimitInstitution(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            agentLimitInstitution
                .delete(id)
                .then((response) => {
                  this.$snotify.success("Deleted Successfully");
                  this.getAgentLimitInstitution();
                  this.agent_limit_institution.institution_ids = [];
                  this.agent_limit_institution.institutions = [];
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>

<style scoped>

</style>