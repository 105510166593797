<template>
  <div class="card" v-if="closedTab">
    <div class="card-header">
      <div class="card-title pt-3 px-3 d-flex justify-content-between">
        <div class="breadcrumb-left">
          <h4>
            Closed Discussion
          </h4>
        </div>
        <div class="breadcrumb-right">
          <div class="card-toolbar">
            <v-btn @click="openDiscussionTab()" small class="btn btn-primary ml-2" dark>
              Back Discussion
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="table-responsive">

        <v-skeleton-loader
            type="table-thead"
            v-if="loading"
        >
        </v-skeleton-loader>

        <v-skeleton-loader
            v-if="loading"
            type="table-row-divider@25"
        >
        </v-skeleton-loader>

        <table class="table">
          <thead>
          <tr class="text-left">
            <th>Name</th>
            <th>Category</th>
            <th>Application</th>
            <th>Added Date</th>
            <th>Published Date</th>
          </tr>
          </thead>
          <tbody class="mt-5">
          <template>
            <tr v-for="(item, index) in discussions" :key="index">
              <td class="px-2">
                <a @click="viewApplicationDiscussionThread(item)" class="d-flex align-items-center">
                  <span class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ item.name }}</span>
                </a>
              </td>
              <td>
                {{item.category_name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm">
                  <span class="text-md text-blue-400">Institution: </span>{{item.application_institution}}
                  <br>
                  <span class="text-md text-blue-400">Academic Program: </span>{{item.application_academic_program}}

                </div>
              </td>
              <td>
                {{item.formatted_created_at ? item.formatted_created_at : '--'}}
              </td>
              <td class="w-100px text-end fs-7  pe-9">
                {{item.readable_created_at}}
              </td>
            </tr>
            <tr v-if="discussions.length == 0">
              <td colspan="6" class="text-center">
                <strong>No items added</strong>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <b-pagination
            v-if="discussions.length > 0"
            class="pull-right mt-7"
            @input="getAllApplicationDiscussion"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            :disabled="loading"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";

const applicationDiscussion=new ApplicationDiscussionService();
export default {
  name: 'ShowAllDiscussionClosed',
  data() {
    return {
      discussions:[],
      userId: null,
      total: null,
      perPage: null,
      page: null,
      loading: false,
      closedTab: false,
    }
  },
  methods:{
    back() {
      this.$tabs.close();
    },
    openTab(){
      this.closedTab = true;
    },
    openDiscussionTab() {
      this.closedTab = false;
      this.$emit('open-discussion');
    },
    getAllApplicationDiscussion(){
      this.loading =  true;
      applicationDiscussion
          .getAllCloseByUser(this.userId,this.page)
          .then(response => {
            this.discussions=response.data.discussions;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {}).finally( this.loading =  false);
    },
    viewApplicationDiscussionThread(discussion){
      if(!discussion.is_read_admin) {
        discussion.is_read_admin = true;
        applicationDiscussion.update(discussion.id, discussion).then(response =>{
          this.getAllApplicationDiscussion();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      this.$router.push({
        name:'discussion-view',
        params:{discussionId: discussion.id}
      });
    }
  },
  mounted() {
    this.userId = this.$route.params.user_id;
    this.getAllApplicationDiscussion();
  }
}
</script>

<style lang="scss">
.table-responsive {
  min-height: 0 !important;
}
</style>