<template>

  <v-dialog v-model="userDialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Address</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined :error="$v.address.address_line_1.$error" dense label="Address Line 1"
                            v-model="address.address_line_1">
                <template v-slot:label>
                  Address Line 1 <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.address_line_1">* {{errors.address_line_1[0]}}<br></span>
              <span class="text-danger" v-if="$v.address.address_line_1.$error">This Address Field is Required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense label="Address Line 2" v-model="address.address_line_2"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined dense label="Suburb" :error="$v.address.suburb.$error" v-model="address.suburb">
                <template v-slot:label>
                  Suburb <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.suburb">* {{errors.suburb[0]}}<br></span>
              <span class="text-danger" v-if="$v.address.suburb.$error">This Suburb Field is Required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete @change="toggleCountry()" v-model="address.country_id" :items="countries" filled outlined
                              dense color="blue-grey lighten-2" label="Select Country" item-text="title" item-value="id">

              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="toggle.stateList==false">
              <v-text-field label="State*" outlined dense :error="$v.address.state.$error" v-model="address.state">
                <template v-slot:label>
                  State <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address.state.$error">This State Field is Required</span>

            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="toggle.stateList">
              <v-autocomplete v-model="address.state_id" :error="$v.address.state_id.$error" :items="states" filled
                              outlined dense color="blue-grey lighten-2" label="Select States" item-text="name" item-value="id">
                <template v-slot:label>
                  State <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.address.state_id.$error">This State Field is Required</span>

            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field outlined :error="$v.address.post_code.$error" dense label="Post Code"
                            v-model="address.post_code">
                <template v-slot:label>
                  Post Code <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.post_code">* {{errors.post_code[0]}}<br></span>
              <span class="text-danger" v-if="$v.address.post_code.$error">This Post Code Field is Required</span>

            </v-col>

            <v-col cols="12" sm="12" md="12">
              <label><strong>Type <span class="text-danger">*</span></strong></label>
              <v-radio-group v-model="address.type" row>
                <v-radio label="Work" value="work" color="indigo"></v-radio>
                <v-radio label="Home" value="home" color="red">
                </v-radio>
                <v-radio label="Other" value="other" color="red">
                </v-radio>
              </v-radio-group>
              <span class="text-danger" v-if="$v.address.type.$error">This Type Field is Required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="address.type=='work'">
              <v-text-field outlined dense label="Business Name" v-model="address.business_name">
                <template v-slot:label>
                  Business Name
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.business_name">* {{errors.business_name[0]}}<br></span>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="font-weight-medium">Status</span>
              <v-switch v-model="address.is_active" :label="address.is_active ?'Active':'Inactive'"
                        hide-details></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="mr-2" text dark medium @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn class="btn btn-primary" medium @click="createOrUpdate()" :loading="loading">
          {{address.id ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import UserAddressService from "@/services/user/UserAddressService";
import StateService from "@/services/country/state/StateService";
import CountryService from "@/services/country/CountryService";
import { required, requiredIf } from "vuelidate/lib/validators";

const country = new CountryService();
const state = new StateService;
const address = new UserAddressService;
export default {
  validations: {
    address: {
      address_line_1: { required },
      type: { required },
      suburb: { required },
      post_code: { required },
      state: {
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList == false;
        })
      },
      state_id: {
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList == true;
        })
      },
    },
  },
  data() {
    return {
      edit: false,
      title: '',
      userDialog: false,
      loading: false,
      address: {
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        state: '',
        state_id: '',
        post_code: '',
        country_id: '',
        type: '',
        business_name: '',
        is_active: 1,
        user_type: '',
      },
      errors: [],
      countries: [],
      states: [],
      toggle: {
        stateList: true
      },
    }
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    toggleCountry() {
      this.getAllState();
    },

    closeDialog() {
      this.userDialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset();
    },

    openDialog() {
      this.userDialog = true;
      this.address.user_id = this.user_id;
    },

    resetForm() {
      this.address = {
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        state: '',
        state_id: '',
        post_code: '',
        country_id: 14,
        type: 'home',
        business_name: '',
        is_active: 1,
        user_id: '',
      };
    },
    createUserAddress() {
      this.edit = false;
      this.resetForm();
      this.openDialog();
      this.title = "Add";
    },

    editAddress(item) {
      this.edit = true;
      this.address = item;
      this.openDialog();
      this.title = "Edit";
    },
    createOrUpdate() {

      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {

      this.address.user_id = this.user_id;
      address
          .create(this.address)
          .then(response => {
            this.$snotify.success("Address created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update() {
      this.loading = true;
      address
          .update(this.address.id, this.address)
          .then(response => {
            this.$snotify.success("Address Update Successfully");
            this.closeDialog();
            this.resetForm();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    getCountries() {
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {
            // console.log(error);
          })
    },
    getAllState() {
      state
          .all(this.address.country_id)
          .then(response => {
            this.states = response.data.data;
          })
          .catch((err) => {
            // console.log(err);
          });
    },
  },
  computed: {
    user_id() {
      return this.$route.params.user_id;
    },
  }
}
</script>
