<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Agent Documents</h4>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('agent', 'edit') && user_setting && user_setting.doc_one == null && user_setting.doc_two  == null"
                      @click="createDocument()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add document
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-row v-if="user_setting">
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_one && user_setting.file_type_one == 'image'">
                <a :href="user_setting.doc_one_file_path.real" target="_blank">
                  <v-img
                      :src="user_setting.doc_one_file_path.real"
                      :lazy-src="user_setting.doc_one_file_path.real"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_one && user_setting.file_type_one == 'pdf'"
              >
                <a :href="user_setting.doc_one_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/pdf.png')"
                      :lazy-src="require('@/assets/media/pdf.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_one && user_setting.file_type_one == 'cvs'"
              >
                <a :href="user_setting.doc_one_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/cvs.png')"
                      :lazy-src="require('@/assets/media/cvs.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_one && user_setting.file_type_one == 'doc'"
              >
                <a :href="user_setting.doc_one_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/document.png')"
                      :lazy-src="require('@/assets/media/document.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three && user_setting.file_type_one == 'xls'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/xls.png')"
                      :lazy-src="require('@/assets/media/xls.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_two && user_setting.file_type_two == 'image'"
              >
                <a :href="user_setting.doc_two_file_path.real" target="_blank">
                  <v-img
                      :src="user_setting.doc_two_file_path.real"
                      :lazy-src="user_setting.doc_two_file_path.real"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>

              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_two && user_setting.file_type_two == 'pdf'"
              >
                <a :href="user_setting.doc_two_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/pdf.png')"
                      :lazy-src="require('@/assets/media/pdf.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_two && user_setting.file_type_two =='cvs'"
              >
                <a :href="user_setting.doc_two_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/cvs.png')"
                      :lazy-src="require('@/assets/media/cvs.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_two && user_setting.file_type_two == 'doc'"
              >
                <a :href="user_setting.doc_two_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/document.png')"
                      :lazy-src="require('@/assets/media/document.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three && user_setting.file_type_two == 'xls'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/xls.png')"
                      :lazy-src="require('@/assets/media/xls.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three  && user_setting.file_type_three == 'image'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="user_setting.doc_three_file_path.real"
                      :lazy-src="user_setting.doc_three_file_path.real"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three && user_setting.file_type_three == 'pdf'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/pdf.png')"
                      :lazy-src="require('@/assets/media/pdf.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three && user_setting.file_type_three == 'cvs'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/cvs.png')"
                      :lazy-src="require('@/assets/media/cvs.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three && user_setting.file_type_three == 'doc'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/document.png')"
                      :lazy-src="require('@/assets/media/document.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_three && user_setting.file_type_three == 'xls'"
              >
                <a :href="user_setting.doc_three_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/xls.png')"
                      :lazy-src="require('@/assets/media/xls.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_four && user_setting.file_type_four == 'image'"
              >
                <a :href="user_setting.doc_four_file_path.real" target="_blank">
                  <v-img
                      :src="user_setting.doc_four_file_path.real"
                      :lazy-src="user_setting.doc_four_file_path.real"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_four && user_setting.file_type_four == 'pdf'"
              >
                <a :href="user_setting.doc_four_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/pdf.png')"
                      :lazy-src="require('@/assets/media/pdf.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_four && user_setting.file_type_four == 'cvs'"
              >
                <a :href="user_setting.doc_four_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/cvs.png')"
                      :lazy-src="require('@/assets/media/cvs.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_four && user_setting.file_type_four == 'doc'"
              >
                <a :href="user_setting.doc_four_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/document.png')"
                      :lazy-src="require('@/assets/media/document.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="d-flex child-flex"
                  cols="3"
                  v-if="user_setting.doc_four && user_setting.file_type_four == 'xls'"
              >
                <a :href="user_setting.doc_four_file_path.real" target="_blank">
                  <v-img
                      :src="require('@/assets/media/xls.png')"
                      :lazy-src="require('@/assets/media/xls.png')"
                      aspect-ratio="1"
                      class="grey lighten-2"
                  >
                  </v-img>
                </a>
              </v-col>
              <v-col
                  class="text-center md:items-center md:justify-between py-12"
                  cols="12"
                  v-if="user_setting.doc_one == null && user_setting.doc_two == null && user_setting.doc_three == null && user_setting.doc_four == null"
              >
                <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto" alt=""/>
                <p>No Document available.</p>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <create-document ref="create-document" :user_setting="user_setting" @refresh="refreshAgent"></create-document>
  </v-app>
</template>

<script>

import UserSettingService from "@/services/user/setting/UserSettingService";
import CreateDocument from "./CreateDocument";

const userSetting = new UserSettingService();
export default {
  name: "Document",
  props: ['user_setting'],
  components: {
    CreateDocument
  },
  methods: {
    createDocument() {
      this.$refs['create-document'].openDialog();
    },
    refreshAgent() {
      this.$emit('refresh');
    }
  }
}
</script>
