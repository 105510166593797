<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Agent commission</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Agent commission
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('agent-commission', 'create')"
                      @click="createAgentCommission()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add agent commission
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="text-left">
                    <th class="px-3" :class="sortedClass('institition')" @click="sortBy('institition')"><strong>
                      Institution</strong></th>
                    <th><strong>Education Levels</strong></th>
                    <th><strong>Commission</strong></th>
                    <th><strong>Remark</strong></th>
                    <th><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in sortedItems" :key="index">
                    <td>
                      <a @click="editAgentCommission(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.institution }}
                      </a>
                    </td>

                    <td>
                      <div v-if="item.education_levels">
                        <ul>
                          <li v-for="(educationLevel, index) in item.education_levels" :key="index">{{educationLevel.title}}</li>
                        </ul>
                      </div>
                    </td>

                    <td>
                      {{ item.currency_symbol }} {{ item.commission }}
                    </td>

                    <td>
                     <span v-html="item.remarks"></span>
                    </td>

                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent-commission', 'edit')">
                              <a @click="editAgentCommission(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('agent-commission', 'delete')">
                              <a class="navi-link" @click.prevent="deleteAgentCommission(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="9" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAgentCommission"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllAgentCommission"></create-and-update>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import AgentCommissionService from "@/services/user/agent/commission/AgentCommissionService";

const agentCommission = new AgentCommissionService();

export default {
  name: "AgentCommission",
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isLoading: false,
      agentCommissions: [],
      institutionIds: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search: {
        is_active: '',
      }
    }
  },

  mounted() {
    this.getAllAgentCommission();
  },

  computed: {

    agentId() {
      return this.$route.params.id;
    },

    sortedItems() {
      const list = this.agentCommissions.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },

  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },

    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    createAgentCommission() {
      this.$refs['create-and-update'].createAgentCommission(this.institutionIds);
    },

    editAgentCommission(item) {
      this.$refs['create-and-update'].editAgentCommission(item);
    },

    getAllAgentCommission() {
      this.loading = true;
      agentCommission
          .paginate(this.search, this.page, this.agentId)
          .then(response => {
            this.agentCommissions = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
            if(this.agentCommissions.length > 0) {
              this.agentCommissions.forEach(item => {
                this.institutionIds.push(item.institution_id)
              })
            }
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchAgentCommission() {
      this.getAllAgentCommission();
    },

    deleteAgentCommission(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            agentCommission
                .delete(this.agentId, id)
                .then((response) => {
                  this.getAllAgentCommission()
                  this.$snotify.success("Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>