<template>
  <div class="row">
    <div class="col-12">
      <div class="card" v-if="closedDiscussionTab">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>
                {{category.name}} Application Discussion <span class="badge badge-square badge-primary -mt-5">{{ category.discussion_count }}</span>
              </h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\ {{category.name}} Application Discussion
              </div>
            </div>
            <div class="breadcrumb-right">
              <div class="card-toolbar">
                <v-btn
                    @click="createApplicationDiscussion()"
                    class="btn btn-primary font-weight-bolder font-size-sm"
                    v-if="checkIsAccessible('application-discussion', 'create')"
                >
                  <i class="fa fa-plus"></i>
                  Add Discussion
                </v-btn>
                <v-btn
                    @click="closedDiscussion()"
                    class="btn btn-standard font-weight-bolder font-size-sm ml-5"
                    v-if="checkIsAccessible('application-discussion', 'create')"
                >
                  <i class="fa fa-close"></i>
                  Discussion Closed
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
            <!--begin::Inbox App - Messages -->
            <div class="">
              <!--begin::Card-->
              <div class="card" v-if="!discussionId">

                <div class="d-flex flex-wrap gap-1 mx-2 p-3" v-if="discussions.length > 0">
                  <!--begin::Checkbox-->
                  <div class="mt-2 ml-4 form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input @change="getAllMarks" v-model="checkAllBox" class="form-check-input cursor-pointer" type="checkbox" />
                  </div>
                  <!--end::Checkbox-->
                  <!--begin::Filter-->
                  <div>
                    <div class="mt-2 topbar">
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="topbar-item text-decoration-none"
                          no-caret
                          left
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-menu"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="markAllRead" class="navi-link">
                              <span class="navi-text">All Read</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                            <a class="navi-link" @click.prevent="markAsRead">
                              <span class="navi-text">Read</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                            <a class="navi-link" @click.prevent="markAsUnRead">
                              <span class="navi-text">Not read</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                            <a class="navi-link" @click.prevent="markAsStarred">
                              <span class="navi-text">Starred</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="discussion_ids.length > 0">
                            <a class="navi-link" @click.prevent="markAsUnStarred">
                              <span class="navi-text">Not starred</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </div>
                  </div>
                </div>

                <div class="card-body mt-1" >
                  <div class="table-responsive">
                      <!--begin::Table-->
                      <v-skeleton-loader
                          type="table-thead"
                          v-if="loading"
                      >
                      </v-skeleton-loader>

                      <v-skeleton-loader
                          v-if="loading"
                          type="table-row-divider@25"
                      >
                      </v-skeleton-loader>
                      <table class="table fs-6 gy-5 my-0">
                        <!--begin::Table head-->
                        <thead class="d-none">
                        <tr class="text-left">
                          <th>Checkbox</th>
                          <th>Name</th>
                          <th>Category</th>
                          <th>Application</th>
                          <th>Agent</th>
                          <th>Published Date</th>
                        </tr>
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody>
                        <tr v-bind:class="{ 'bg-gray-200': !item.is_read_admin}" v-for="(item, index) in discussions" :key="index">
                          <td class="ps-9">
                            <!--begin::Checkbox-->
                            <div class="form-check form-check-sm form-check-custom form-check-solid mt-3">
                              <input @change="getMark(index, item)" v-model="checkBox[index]" class="form-check-input" type="checkbox" value="1" />
                            </div>
                            <!--end::Checkbox-->
                          </td>
                          <td class="min-w-80px">
                            <v-icon @click="makeStarred(item)" class="mx-3 mt-5" x-small :color="item.is_starred ? 'primary' : ''">fas fa-star</v-icon>
                            <v-icon @click="makePinned(item)" class="mx-3 mt-5" x-small :color="item.is_pinned ? 'primary' : ''">fas fa-bookmark</v-icon>
                          </td>
                          <!--begin::Author-->
                          <td class="w-150px w-md-175px">
                            <a @click="viewApplicationDiscussionThread(item)" class="d-flex align-items-center text-dark">
                              <!--begin::Avatar-->
                              <div class="symbol symbol-35px me-3">
                                <div class="symbol-label bg-light-danger">
                                  <span class="text-danger">{{ item.symbol_label }}</span>
                                </div>
                              </div>
                              <!--end::Avatar-->
                              <!--begin::Name-->
                              <span class="fw-semibold">{{ item.name }}</span>
                              <!--end::Name-->
                            </a>
                          </td>
                          <!--end::Author-->
                          <!--begin::Title-->
                          <td>
                              <!--begin::Heading-->
                              <div class="fw-bold"><b>Institution: </b> {{item.application_institution ? item.application_institution : 'N/A'}}</div>
                              <!--end::Heading-->
                              <!--begin::Badges-->
                              <span class="fw-bold"><b>Academic Program: </b> {{item.application_academic_program ? item.application_academic_program : 'N/A'}}</span>
                              <!--end::Badges-->
                          </td>
                          <!--end::Title-->

                          <td class=" text-center mt-5 fs-7 pe-9">
                            <br>
                            <b >Agent: </b> <span class="fw-semibold" > {{item.agent ? item.agent : 'N/A' }}</span>
                          </td>

                          <!--begin::Date-->
                          <td class="w-100px text-end fs-7 pe-9">
                            <span class="fw-semibold">{{item.readable_created_at}} <br> {{ item.formatted_created_at ? item.formatted_created_at : '--'}}</span>
                          </td>
                          <!--end::Date-->
                        </tr>
                        <tr v-if="discussions.length == 0">
                          <td class="text-center font-weight-bold px-3" colspan="7">No Application Discussion</td>
                        </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                      <!--end::Table-->
                  </div>
                </div>
              </div>
              <!--end::Card-->

              <discussion-view v-if="discussionId" :discussion-id="discussionId" @view-discussion="viewDiscussion" :user_id="user_id"></discussion-view>
            </div>
            <!--end::Inbox App - Messages -->
        </div>

        <create-and-update :user_id="user_id" :agent_id="agent_id" ref="create-application-discussion" @refresh="getAllApplicationDiscussion" :application-id="this.applicationId"></create-and-update>
      </div>

      <show-all-discussion-closed ref="closed-application-discussion" @open-discussion="openDiscussion"></show-all-discussion-closed>
    </div>
  </div>
</template>
<script>
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";
import DiscussionView from "@/view/pages/view/application/discussion/View";
import CreateAndUpdate from "./CreateAndUpdate";
import ShowAllDiscussionClosed
  from "@/view/pages/view/user/student/profile/components/application-discussion/ShowAllDiscussionClosed";

const applicationDiscussion=new ApplicationDiscussionService();
export default {
  name: 'ApplicationDiscussion',
  components: {ShowAllDiscussionClosed, CreateAndUpdate, DiscussionView},
  props: ['applicationId', 'user_id', 'support', 'agent_id'],
  data() {
    return {
      discussionId: null,
      category: {},
      discussions:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      closedDiscussionTab: true,
      checkAllBox: false,
      checkBox: [false],
      discussion_ids: [],
      search: {
        user_id: '',
        type: '',
        application_id: '',
        agent_id: '',
        is_read: '',
        is_starred: '',
        is_pinned: '',
        is_active: '',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Read tickets', value: '1'},
        {name: 'Not read tickets', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        {name: 'Refund', value: 'refund'},
        {name: 'Payment', value: 'payment'},
        {name: 'Feedback', value: 'feedback'},
        {name: 'Support', value: 'support'},
        {name: 'Other', value: 'other'},
      ],
    }
  },
  methods:{
    viewDiscussion() {
      this.discussionId = null;
      this.getAllApplicationDiscussion();
    },
    getMark(event, item) {
      if(event) {
        this.discussion_ids.push(item.id);
      }else {
        this.discussion_ids.splice(this.discussion_ids.indexOf(item.id), 1)
      }
    },
    createApplicationDiscussion() {
      this.$refs['create-application-discussion'].createApplicationDiscussion()
    },
    createSupport() {
      this.$refs['create-application-discussion'].createSupport()
    },
    closedDiscussion() {
      this.closedDiscussionTab = false;
      this.$refs['closed-application-discussion'].openTab()
    },
    openDiscussion() {
      this.closedDiscussionTab = true;
    },
    getAllApplicationDiscussion(){
      this.loading =  true;
      applicationDiscussion
          .paginate(this.search,this.page)
          .then(response => {
            this.discussions=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.getUnReadTicket();
          })
          .catch((err) => {});
    },

    viewApplicationDiscussionThread(discussion){
      if(!discussion.is_read_admin) {
        discussion.is_read_admin = true;
        applicationDiscussion.update(discussion.id, discussion).then(response =>{
          this.getAllApplicationDiscussion();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      this.discussionId = discussion.id
      this.discussion_ids = [];
      this.checkAllBox = false;
      this.checkBox = [false];
    },

    markAsRead() {
      let discussion_ids = this.setData('is_read_admin', 1);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Successfully Marked As Read");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkAllBox = false;
          this.checkBox = [false];
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAsUnRead() {
      let discussion_ids = this.setData('is_read_admin', 0);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Marked as not read");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkBox = [false];
          this.checkAllBox = false;
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAllRead() {
      applicationDiscussion.markAllReadTicket().then(response => {
        this.getAllApplicationDiscussion();
      }).catch((err) => {
        this.$snotify.error("Oops something went wrong");
      });
    },

    markAsStarred() {
      let discussion_ids = this.setData('is_starred', 1);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Successfully Marked As Starred");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkAllBox = false;
          this.checkBox = [false];
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    markAsUnStarred() {
      let discussion_ids = this.setData('is_starred', 0);
      if(this.discussion_ids.length > 0) {
        applicationDiscussion.multipleUpdate(discussion_ids).then(response =>{
          this.$snotify.success("Marked as not starred");
          this.getAllApplicationDiscussion();
          this.discussion_ids = [];
          this.checkBox = [false];
          this.checkAllBox = false;
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    getAllMarks() {
      if(this.checkAllBox) {
        this.discussions.forEach((item, index) => {
          this.checkBox[index] = true;
          this.discussion_ids.push(item.id);
        });
      }else {
        this.discussion_ids = [];
        this.checkBox = [false];
      }
    },

    setData(index, change) {
      let data = [];
      this.discussion_ids.forEach((item) => {
        data.push(item);
      })
      return {[`${index}`]: change, data: data};
    },

    makeStarred(discussion) {
      let data = {
        is_starred: '',
      }
      if(discussion.is_starred) {
        data.is_starred = 0;
      } else {
        data.is_starred = 1;
      }
      applicationDiscussion.update(discussion.id, data).then(() => {
        this.$snotify.success("Discussion Starred Successfully");
        this.getAllApplicationDiscussion();
      }).then(() => {});
    },

    makePinned(discussion) {
      let data = {
        is_pinned: '',
      }
      if(discussion.is_pinned) {
        data.is_pinned = 0;
      } else {
        data.is_pinned = 1;
      }
      applicationDiscussion.update(discussion.id, data).then(() => {
        this.$snotify.success("Discussion Pinned Successfully");
        this.getAllApplicationDiscussion();
      }).then(() => {});
    },

  },
  mounted() {
    this.search.user_id = this.user_id;
    this.search.application_id = this.applicationId ? this.applicationId : '';
    if(this.support) {
      this.search.type = 'support';
    }
    if(this.agent_id) {
      this.search.agent_id = this.agent_id;
    }
    this.getAllApplicationDiscussion();
  }
}
</script>

<style lang="scss">
.table-responsive {
  min-height: 0 !important;
}
</style>