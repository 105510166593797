<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} {{ application_discussion.type == 'support' ? 'Support' : 'Application Discussion' }}</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text v-if="applications.length > 0 || application_discussion.type == 'support'">
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                :error="$v.application_discussion.name.$error"George Haney
                dense
                v-model="application_discussion.name"
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name">{{
                errors.name[0]
              }}</span>
              <span
                class="text-danger"
                v-if="$v.application_discussion.name.$error"
                >This Field is required</span
              >
            </v-col>

            <v-col cols="12" md="12">
              <div class="text-body">Description</div>
              <ckeditor
                :error="$v.application_discussion.description.$error"
                :config="editorConfig"
                v-model="application_discussion.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="errors.description">{{
                errors.description[0]
              }}</span>
              <span
                class="text-danger"
                v-if="$v.application_discussion.description.$error"
                >This Field is required</span
              >
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="application_discussion.category_id"
                :items="discussionCategories"
                outlined
                item-text="name"
                item-value="id"
                dense
                :error="$v.application_discussion.category_id.$error"
                menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Category <span class="text-danger">*</span>
                </template>
              </v-select>
              <span
                class="text-danger"
                v-if="$v.application_discussion.category_id.$error"
                >This Field is required</span
              >
              <span class="text-danger" v-if="errors.category_id">{{
                errors.category_id[0]
              }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="application_discussion.type"
                :items="types"
                outlined
                item-text="name"
                item-value="id"
                dense
                :error="$v.application_discussion.type.$error"
                :disabled="is_type"
                menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span
                class="text-danger"
                v-if="$v.application_discussion.type.$error"
                >This Field is required</span
              >
              <span class="text-danger" v-if="errors.type">{{
                errors.type[0]
              }}</span>
            </v-col>

            <v-col cols="12" sm="12" v-if="application_discussion.type != 'support' && applicationId == null">
              <v-select
                v-model="application_discussion.application_id"
                :items="applications"
                item-text="institution_program"
                item-value="id"
                :error="$v.application_discussion.application_id.$error"
                hide-selected
                outlined
                dense
                clearable
              >
                <template v-slot:label>
                  Application <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.application_discussion.application_id.$error"
                >This Field is required</span
              >
            </v-col>
            <v-col cols="12" sm="12" md="4">
              For Agent <span class="text-danger">*</span>
              <v-switch
                  v-model="application_discussion.application_for"
                  :label="application_discussion.application_for ? 'For Agent' : 'Not For Agent'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              Status <span class="text-danger">*</span>
              <v-switch
                v-model="application_discussion.is_active"
                :label="
                  application_discussion.is_active ? 'Active' : 'Inactive'
                "
                color="primary"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>



      <v-card-text v-else>
          <h4>You Cannot Start Discussion At the Moment Because Of No Availability Of Active Application.</h4>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="mr-2" text dark medium @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          v-if="applications.length > 0 || application_discussion.type == 'support'"
          class="btn btn-primary"
          medium
          @click="createOrUpdate()"
          :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";
import ApplicationDiscussionCategoryService from "@/services/application/discussion/category/ApplicationDiscussionCategoryService";
import UserService from "@/services/user/UserService";
import ApplicationService from "@/services/application/ApplicationService";

const user = new UserService();
const applicationDiscussion = new ApplicationDiscussionService();
const supportCategory = new ApplicationDiscussionCategoryService();
const application = new ApplicationService();

export default {
  name: "CreateAndUpdate",
  props: ['user_id', 'agent_id', 'applicationId'],
  validations: {
    application_discussion: {
      name: { required },
      category_id: { required },
      application_id: {required: requiredIf(function (){
          return this.application_discussion.type != 'support';
        }) },
      description: {},
      user_reply_not_required: { required },
      type: { required },
      is_active: { required },
    },
  },
  data() {
    return {
      title: "",
      edit: false,
      errors: [],
      dialog: false,
      loading: false,
      isLoading: false,
      is_type: false,
      discussionCategories: [],
      applications: [],
      application_discussion: {
        name: "",
        category_id: '1',
        application_id: null,
        description: "",
        type: "general",
        user_reply_not_required: 0,
        user_id: null,
        agent_id: null,
        is_read_admin: 0,
        is_read_user: 0,
        application_for: true,
        is_active: 1,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
      types: [
        { name: "General", id: "general" },
        { name: "Refund", id: "refund" },
        { name: "Payment", id: "payment" },
        { name: "Feedback", id: "feedback" },
        { name: "Support", id: "support" },
        { name: "Other", id: "other" },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createApplicationDiscussion() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
      this.application_discussion.user_id = this.user_id;
      this.application_discussion.application_id = this.applicationId;
    },
    createSupport() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.is_type = true;
      this.title = "Add";
      this.application_discussion.type = 'support'
      if(this.agent_id)
        this.application_discussion.agent_id = this.agent_id;
      this.application_discussion.user_id = this.user_id;
    },
    getAllApplication() {
      application
          .getAllApplication(this.user_id)
          .then((response) => {
            this.applications = response.data.applications;
          })
          .catch((err) => {});
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      applicationDiscussion
        .create(this.application_discussion)
        .then((response) => {
          this.$snotify.success("Application Discussion created successfully");
          this.resetForm();
          this.closeDialog();
          this.loading = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.loading = false;
          this.errors = err.response.data.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },
    update: function () {
      this.loading = true;
      applicationDiscussion
        .update(this.application_discussion.id, this.application_discussion)
        .then((response) => {
          this.$snotify.success("Application Discussion updated successfully");
          this.resetForm();
          this.closeDialog();
          this.loading = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.loading = false;
          this.errors = err.response.data.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },
    resetForm() {
      this.application_discussion = {
        name: "",
        category_id: '',
        application_id: null,
        description: "",
        type: "general",
        user_reply_not_required: 0,
        user_id: null,
        agent_id: null,
        is_read_admin: 0,
        is_read_user: 0,
        application_for: true,
        is_active: 1,
      };
      this.$v.$reset();
      this.errors = [];
    },
    getSupportCategories() {
      supportCategory
        .all()
        .then((response) => {
          this.discussionCategories = response.data.discussionCategories;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getSupportCategories();
    this.getAllApplication();
  },
};
</script>
