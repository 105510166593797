<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ title }}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{title}}
                </div>
              </div>
              <div class="breadcrumb-right" v-if="title != 'Finance'">
                <div class="card-toolbar">
                  <v-btn
                      @click="createApplicantPayment()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">

            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                      label="Status"
                      v-model="search.status"
                      v-on:keyup.enter="searchApplicationPayments"
                      @input="search.status = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="value"
                      :items="statuses"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" class="text-right">
                  <v-btn
                      @click.prevent="searchApplicationPayments"
                      class="mt-1 btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" v-if="title == 'Finance'"><strong>Additional Info</strong></th>
                  <th><strong>Title & Service</strong></th>
                  <th><strong>Additional info</strong></th>
                  <th class="text-center px-3" v-if="title == 'Finance'"><strong>Remarks</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                  <template>
                    <tr v-for="item in applicationPayments" :key="item.id">
                      <td v-if="title == 'Finance'" class="text-left font-size-lg">
                        <p v-if="item.user_id">
                          <b>Student name:</b><router-link :to="{name:'student-profile', params:{'user_id':item.user_id}}">
                             {{ item.user_name }}
                          </router-link>
                        </p>
                        <p v-if="item.agent_id">
                          <b>Agent name:</b><router-link :to="{name:'user-agent-profile', params:{'id':item.agent_id}}">
                             {{ item.agent_name }}
                          </router-link>
                        </p>
                        <div v-if="item.application_id">
                          <b> Reference No.: </b> <a class="cursor-pointer" @click="openApplicationDetail(item)"> <b>
                          {{
                            item.application_id }}</b></a> <br />
                          <span v-if="item.application_title" class="badge badge-primary"> {{ item.application_title }} </span>
                        </div>
                      </td>

                      <td class="text-left font-size-lg">
                        {{ item.title }}
                        <br>
                        {{ item.service_title }}
                      </td>

                      <td class="font-size-lg">
                        <b>Amount: </b> {{ item.currency_symbol }} {{ item.amount }}<br>
                        <b>Payment date: </b> {{ item.payment_date ? item.payment_date : 'N/A' }}
                      </td>

                      <td class="text-center font-size-lg" v-if="title == 'Finance'">
                        <span v-if="item.remarks" v-html="item.remarks"></span>
                        <span v-else>N/A</span>
                      </td>

                      <td class="font-size-lg">
                        <span class="badge"
                              :class="item.status == 'pending' ? 'badge-warning' : 'badge-primary'
                        "> {{ item.status }} </span>
                      </td>

                      <td class="text-center font-size-lg">
                        <template v-if="item.status != 'complete'">
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="item.status == 'pending' && item.payment_method == 'bank'">
                                <a @click="markAsPaid(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-dollar-sign</v-icon>
                                  </span>
                                  <span class="navi-text">Mark as paid</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="item.receipt_path && item.payment_method == 'bank'">
                                <a :href="item.receipt_path.real" target="_blank" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                  <span class="navi-text">View receipt</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="item.status != 'pending'">
                                <a @click="viewInvoice(item.invoice_url)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-dollar-sign</v-icon>
                                  </span>
                                  <span class="navi-text">View Invoice</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="title != 'Finance'">
                                <a @click="editApplicantPayment(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteApplicationPayment(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </template>
                    <tr  v-if="!loading && applicationPayments.length == 0">
                      <td class="text-center md:items-center md:justify-between py-6" colspan="10">
                        <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto" alt=""/>
                        <p>No transaction records available.</p>
                        <v-btn
                            v-if="title != 'Finance'"
                            @click="createApplicantPayment" class="btn btn-secondary">
                          Add Payment Invoice/Quote
                        </v-btn>
                      </td>
                    </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="applicationPayments.length > 0"
                  class="pull-right mt-7"
                  @input="getApplicationPayments"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>

    <CreateAndUpdateApplicationPayment
        ref="create-update-payment"
        @refresh="getApplicationPayments"
    ></CreateAndUpdateApplicationPayment>

  </v-app>
</template>

<script>
import UserPaymentService from "@/services/user/payment/UserPaymentService";
import CreateAndUpdateApplicationPayment from "./CreateAndUpdateApplicationPayment.vue";
import {API_URL} from '@/common/config.js'

const paymentService = new UserPaymentService();

export default {
  props: ['agent_id', 'user_id', 'application_id'],
  components: {
    CreateAndUpdateApplicationPayment
  },
  data() {
    return {
      total: null,
      loading: true,
      perPage: null,
      page: null,
      currentId: null,
      applicationPayments: [],
      statuses: [
        { name: "Pending", value: "pending" },
        { name: "Paid", value: "paid" },
      ],
      search: {
        application_id: '',
        status: 'pending',
      },
    }
  },
  computed: {
    title() {
      if(this.$route.name == 'finance') {
        return 'Finance';
      }
      return 'Application Payment';
    }
  },
  methods: {
    openApplicationDetail(application) {
      this.$tabs.close().then(() => {
        this.$router.push({ name: 'application-detail', params: { id: application.application_id } })
      })
    },
    searchApplicationPayments() {
      this.page = 1;
      this.getApplicationPayments();
    },

    getApplicationPayments() {
      this.loading = true;
      paymentService
          .paginate(this.search, this.page, this.currentId)
          .then((response) => {
            this.applicationPayments = response.data.data;
            this.loading = false;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    viewInvoice(invoiceUrl) {
      if (invoiceUrl) {
        window.open(invoiceUrl);
      } else {
        this.$snotify.error("No invoice found.");
      }
    },

    createApplicantPayment() {
      this.$refs['create-update-payment'].createPayment(this.agent_id, this.application_id, this.user_id);
    },

    editApplicantPayment(item) {
      this.$refs['create-update-payment'].editPayment(item);
    },

    markAsPaid(item) {
      this.$confirm({
        message: `Are you sure ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.title == 'Finance') {
              this.currentId = item.user_type == 'student' ? item.user_id : item.agent_id;
            }
            paymentService
                .createInvoice(this.currentId, item.id)
                .then(() => {
                  if(this.title == 'Finance'){
                    this.currentId = null;
                  }
                  this.getApplicationPayments();
                  this.$snotify.success("Update Successfully.");
                })
                .catch((err) => {
                  this.loading = false;
                })
                .finally(() => {
                  this.loading = false;
                })
          }
        },
      });

    },
    deleteApplicationPayment(paymentId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            if(this.title == 'Finance') {
              paymentService.deletePayment(paymentId)
                  .then((response) => {
                    this.getApplicationPayments();
                    this.$snotify.success("Payment Deleted Successfully.");
                  })
                  .catch((err) => {
                  });
            } else {
              paymentService.delete(this.currentId, paymentId)
                  .then((response) => {
                    this.getApplicationPayments();
                    this.$snotify.success("Payment Deleted Successfully.");
                  })
                  .catch((err) => {
                  });
            }
          }
        },
      });
    }
  },
  mounted() {
    if(this.application_id){
      this.search.application_id = this.application_id;
      this.search.user_type = 'application';
    }

    if(this.agent_id){
      this.currentId = this.agent_id;
      this.search.user_type = 'agent';
    }

    if(this.user_id){
      this.currentId = this.user_id;
      this.search.user_type = 'student';
    }

    if(!this.user_id && !this.agent_id && !this.application_id){
      this.currentId = null;
      this.search.user_type = '';
      this.search.status = '';
    }

    if(this.title !== 'Finance'){
      this.search.status = '';
    }

    this.getApplicationPayments();
  }
}
</script>
