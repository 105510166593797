<template>
  <v-app>
    <template v-if="invoices && invoices.length>0">
      <v-row>
        <v-col cols="12">
          <table class="table table-responsive">
            <thead>
              <th>Invoice No</th>
              <th>Invoiced to</th>
              <th>Payment Method</th>
              <th>Sub Total</th>
              <th>Tax</th>
              <th>Total</th>
              <th>Payment Date</th>
            </thead>
            <tbody>
              <tr v-for="(invoice, index) of invoices" :key="index">
                <td>{{invoice.invoice_no}}</td>
                <td>{{invoice.invoiced_to}}</td>
                <td><span class="badge badge-primary text-uppercase">{{invoice.payment_method}}</span></td>
                <td>Rs.{{invoice.sub_total}}</td>
                <td>Rs.{{invoice.tax_total}}</td>
                <td>Rs.{{invoice.invoice_total}}</td>
                <td>{{invoice.created_at}}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col class="text-center md:items-center md:justify-between py-12">
          <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"/>
          <p>No processing has started for this user.</p>
        </v-col>
      </v-row>
    </template>
  </v-app>
</template>

<script>
export default {
  name: "Invoice",
  props: ['invoices'],
}
</script>