import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class VisaApplicationStatusStateService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/visa-application-status-state';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    sort(data = []) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getByCountry(countryId) {
        let url = `${this.#api}/country/${countryId}`;
        return apiService.get(url);
    }

    getAllVisaStateStatus(applicationStatusId) {
        let url = `${this.#api}/${applicationStatusId}/get/all/candidate-inquiry-info`;
        return apiService.get(url);
    }

    getAllChangeVisaStatus(applicationStatusId, all = false) {
        let url = `${this.#api}/${applicationStatusId}/get/all/change-visa-status`;
        if(all) {
            url = `${url}?checkedAll=${all}`;
        }
        return apiService.get(url);
    }
}