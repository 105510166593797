<template>
  <div>
    <v-app>
    <v-card raised>
      <v-card-title>
        Address
        <v-spacer></v-spacer>
        <v-btn @click="createAddress()" class="btn btn-primary"
            medium>
          <v-icon  x-small>
            fas fa-plus
          </v-icon>
          Add New
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-expansion-panels inset v-if="user_addresses.length >0">
            <v-expansion-panel  v-for="(address,index) in user_addresses" :key="index" >
              <v-expansion-panel-header v-slot:default="{ open }">
                <div class="row"  >
                  <v-col cols="9" v-if="!open" >
                    <span class="font-weight-medium">{{ address.address_line_1 }}</span>
                  </v-col>
                  <v-col cols="2"  v-if="!open"
                  >
              <span class="badge badge-success text-lg text-right"
                    v-bind:class="{ 'badge-success': address.is_active, 'badge-danger': !address.is_active }"
              >{{ address.status_text }}</span>
                  </v-col>

                  <v-col cols="9" v-if="!open">
                    <span class="subtitle-1">{{address.suburb}},{{address.state_name ? address.state_name : address.state }}</span>
                  </v-col>
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="card card-custom">
                  <div class="card-header py-3">
                    <div class="card-title align-items-start flex-column">
                      <h3 class="card-label font-weight-bolder text-dark">
                        Address Information
                      </h3>
                    </div>

                    <div class="card-toolbar">
                      <v-btn class="mx-2" fab small  @click="editAddress(address)">
                        <v-icon dark style="color: orange">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn class="mx-2" fab small  @click="deleteAddress(address.id)">
                        <v-icon dark style="color: red">
                          fa fa-times
                        </v-icon>
                      </v-btn>

                    </div>

                  </div>

                  <form class="form">
                    <div class="card-body">

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">Address Line 1</label>
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.address_line_1}}</a>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right"
                        >Address Line 2</label
                        >
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.address_line_2 ? address.address_line_2 : 'N/A' }}</a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">Suburb</label>
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.suburb ? address.suburb : 'N/A' }}</a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">State</label>
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.state_name ? address.state_name : address.state }}</a>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">Postal code</label>
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.post_code ? address.post_code : 'N/A' }}</a>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">Country</label>
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.country_name ? address.country_name : 'N/A' }}</a>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">Type</label>
                        <div class="col-lg-9 col-xl-6">
                          <a class="font-size-h6 text-dark-75 text-hover-primary">{{address.type_text ? address.type_text : 'N/A' }}</a>
                        </div>
                      </div>
                      <div class="form-group row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-form-label text-right">Status</label>
                        <div class="col-lg-9 col-xl-6">
                    <span class="badge badge-success text-lg`"
                          v-bind:class="{ 'badge-success': address.is_active, 'badge-danger': !address.is_active }"
                    >{{ address.status_text }}</span>

                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
          <span class="text-center" v-else>
            <v-col class="text-center md:items-center md:justify-between py-12">
                        <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"/>
                        <p>No address available.</p>
                        <v-btn @click="createAddress()" class="btn btn-secondary">
                            Add address
                        </v-btn>
            </v-col>
          </span>
        </v-row>
      </v-card-text>

    </v-card>

  </v-app>
    <create-address ref="create-address" @refresh="getAllUserAddress"></create-address>
  </div>
</template>
<script>
import UserAddressService from "@/services/user/UserAddressService";
import CreateAddress from "./CreateAddress";

const userAddress=new UserAddressService();
export default {
  name: "UserAddress",
  props: ['user_id'],
  components:{
    CreateAddress,
  },
  data(){
    return{
      isLoading:false,
      user_addresses:[],
      total: null,
      perPage: null,
      lastPage: null,
      page: null,
      search:{
        user_id:'',
      },
    }
  },
  methods:{
    getAllUserAddress() {
      this.isLoading=true;
      this.search.user_id = this.userId;
      userAddress
          .paginate(this.search, this.page)
          .then(response => {
            this.user_addresses = response.data.data;
            this.page = response.data.meta.current_page;
            this.lastPage = response.data.meta.last_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          })
          .finally(() => {
            this.isLoading=false;
          });
    },
    createAddress(){
      this.$refs['create-address'].createUserAddress();
    },
    editAddress(item){
      this.$refs['create-address'].editAddress(item);
    },
    deleteAddress(addressId){
      this.$confirm({
        message: `Are you sure you want to remove this address? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            userAddress
                .delete(addressId)
                .then((response) => {
                  this.getAllUserAddress();
                  this.$snotify.success("Address Deleted ");
                })
                .catch(() => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }

  },
  mounted(){
    this.getAllUserAddress();
  },
  computed:{
    userId() {
      if(this.$route.params.user_id) {
        return this.$route.params.user_id;
      } else {
        return this.user_id;
      }
    },
  }
}
</script>