<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Close Discussion</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              Remarks
              <ckeditor
                  :config="editorConfig"
                  v-model="discussion.closing_remarks"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.discussion.closing_remarks.$error">This Field is required</span>
            </v-col>

          </v-row>
        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";

const applicationDiscussion = new ApplicationDiscussionService();

export default {
  name: "CloseDiscussion",
  data() {
    return {
      edit: false,
      dialog: false,
      loading: false,
      discussion: {
        is_closed: 1,
        closing_remarks: ''
      },
      editorConfig: {
        versionCheck: false,
        width: 700,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
    }
  },
  validations: {
    discussion: {
      closing_remarks: {required}
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    editApplicationDiscussion(item) {
      this.$v.$reset();
      this.dialog = true;
      this.edit = true;
      this.discussion = item;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.update();
      }
    },
    update: function () {
      this.loading = true;
      applicationDiscussion
          .closeTicket(this.discussion.id, this.discussion)
          .then(response => {
            this.$snotify.success("Application Discussion is Closed");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
            this.$emit('back');
            // this.$router.push({
            //   name: 'student-profile',
            //   params: {user_id: this.discussion.user_id}
            // });
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          }).finally(() => this.loading = false);
    },
    resetForm() {
      this.$v.$reset();
      this.errors = [];
      this.discussion = {
        is_closed: 1,
        closing_remarks: ''
      }
    }
  },
}
</script>
