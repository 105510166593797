<template>
  <v-app>
    <v-list two-line>
      <v-list-item class="d-flex justify-space-between">
        <v-list-item-title>
          <h4> User Profile </h4>
        </v-list-item-title>
        <div class="d-flex">
          <v-btn
              class="btn mr-3 text-white btn-primary"
              small
              @click="editUserSetting"
          >
            <i class="fa fa-edit"></i>
            Change Details
          </v-btn>
        </div>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.full_name ? user.full_name : 'N/A' }}</v-list-item-title>
          <v-list-item-subtitle>Full Name</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-flag
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.gender_text ? user.gender_text : 'N/A' }}</v-list-item-title>
          <v-list-item-subtitle>Gender</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-calendar
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.date_of_birth ? user.formatted_date_of_birth : 'N/A' }}</v-list-item-title>
          <v-list-item-subtitle>DOB</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.email ? user.email : 'N/A' }}</v-list-item-title>
          <v-list-item-subtitle>Email</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.phone ? user.phone : '' }} {{ user.mobile ? user.mobile : '' }}</v-list-item-title>
          <v-list-item-subtitle>Phone</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user_setting">
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-flag
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Citizen of</v-list-item-title>
          <v-list-item-subtitle>{{ user_setting.citizen_of ? user_setting.citizen_of : 'N/A' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.business_name">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-briefcase
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Business name</v-list-item-title>
          <v-list-item-subtitle>{{ user.business_name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.business_description">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-business-time
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Business description</v-list-item-title>
          {{ user.business_description }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.website">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-globe
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Website</v-list-item-title>
          <v-list-item-subtitle><a :href="user.website" target="_blank">Website Link</a> </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.year_of_start">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-calendar-days
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Year of start</v-list-item-title>
          <v-list-item-subtitle>{{ user.year_of_start }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.city_of_recruitment">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-location-dot
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>City of recruitment</v-list-item-title>
          <v-list-item-subtitle>{{ user.city_of_recruitment }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.estimated_students">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-users
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Estimated students</v-list-item-title>
          <v-list-item-subtitle>{{ user.estimated_students }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user_setting">
        <v-list-item-icon>
          <v-icon color="indigo">
            fas fa-percent
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Commission percentage</v-list-item-title>
          <v-list-item-subtitle>{{ user_setting.commission_percentage ? user_setting.commission_percentage : 'N/A' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <v-list-item v-if="user.plan_id">
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-flag
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Plan Subscribed - {{ user.plan_title }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ user.plan_expiry_date_format ? user.plan_expiry_date_format : 'N/A' }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon style="font-size: 15px;" class="mr-2" @click="editSubscriptionDate">fas fa-edit</v-icon>
          <v-icon style="font-size: 15px" @click="removeSubscription">fas fa-trash</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-flag
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Can Agent Update Status ?</v-list-item-title>
          <v-list-item-subtitle>
            <span class="badge badge-primary" v-bind:class="{'badge-primary': user.can_update_status, 'badge-danger': !user.can_update_status}">{{ user.can_update_status ? 'Yes' : 'No' }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-flag
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Can Agent Update History Status ?</v-list-item-title>
          <v-list-item-subtitle>
            <span class="badge badge-primary" v-bind:class="{'badge-primary': user.can_update_history_status, 'badge-danger': !user.can_update_history_status}">{{ user.can_update_history_status ? 'Yes' : 'No' }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- user setting models -->
      <v-dialog
          v-model="dialog"
          max-width="800"
          scrollable
          persistent
      >
        <v-card>
          <v-toolbar dark>
            <v-card-title class="text-h5">
              <span>Update Agent Details</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row class="mt-2">
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="userData.first_name"
                      outlined
                      dense
                      :error="$v.userData.first_name.$error"
                  >
                    <template v-slot:label>
                      First Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.first_name.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.first_name">* {{ errors.first_name[0] }}</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="userData.middle_name"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Middle Name
                    </template>
                  </v-text-field>
                  <!--                  <span class="text-danger" v-if="$v.userData.middle_name.$error">This field is required</span>-->
                  <!--                  <span class="text-danger" v-if="errors.middle_name">* {{ errors.middle_name[0] }}</span>-->
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="userData.last_name"
                      outlined
                      dense
                      :error="$v.userData.last_name.$error"
                  >
                    <template v-slot:label>
                      Last Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.last_name.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.last_name">* {{ errors.last_name[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.mobile"
                      outlined
                      :error="$v.userData.mobile.$error"
                      dense
                  >
                    <template v-slot:label>
                      Mobile
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.mobile.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.mobile">* {{ errors.mobile[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.phone"
                      outlined

                      dense
                  >
                    <template v-slot:label>
                      Phone
                    </template>
                  </v-text-field>
                  <!--                  <span class="text-danger" v-if="$v.userData.phone.$error">This field is required</span>-->
                  <!--                  <span class="text-danger" v-if="errors.phone">* {{ errors.phone[0] }}</span>-->
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.work_phone"
                      outlined

                      dense
                  >
                    <template v-slot:label>
                      Work Phone
                    </template>
                  </v-text-field>
                  <!--                  <span class="text-danger" v-if="$v.userData.work_phone.$error">This field is required</span>-->
                  <!--                  <span class="text-danger" v-if="errors.work_phone">* {{ errors.work_phone[0] }}</span>-->
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                      :items="countries"
                      item-text="title"
                      item-value="id"
                      v-model="user_setting.country_of_citizen_id"
                      outlined
                      :error="$v.user_setting.country_of_citizen_id.$error"
                      dense
                  >
                    <template v-slot:label>
                      Country
                    </template>
                  </v-autocomplete>
                  <span class="text-danger"
                        v-if="$v.user_setting.country_of_citizen_id.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.country_of_citizen_id">* {{
                      errors.country_of_citizen_id[0]
                    }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-combobox
                      v-model="user_setting.first_language"
                      label="First Language"
                      outlined
                      :error="$v.user_setting.first_language.$error"
                      small-chips
                      multiple
                      dense
                  >
                  </v-combobox>
                  <span class="text-danger" v-if="$v.user_setting.first_language.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.first_language">* {{ errors.first_language[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user_setting.commission_percentage"
                      label="Commission Percentage"
                      outlined
                      dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" v-if="user_setting.refused_visa">
                  <label>Details <span class="text-danger">*</span> </label>
                  <ckeditor
                      :config="editorConfig"
                      v-model="user_setting.details"
                  >
                  </ckeditor>
                  <span class="text-danger" v-if="$v.user_setting.details.$error">This Field is required</span>
                  <span class="text-danger" v-if="errors.details">* {{ errors.details[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <label>Gender <span class="text-danger">*</span></label>
                  <v-radio-group
                      v-model="userData.gender"
                      :error="$v.userData.gender.$error"
                      row
                  >
                    <v-radio
                        label="Male"
                        value="male"
                    ></v-radio>
                    <v-radio
                        label="Female"
                        value="female"
                    ></v-radio>
                    <v-radio
                        label="Prefer Not to Say"
                        value="prefer_not_to_say"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="3">
                  Future lead
                  <v-switch
                      v-model="userData.is_future_lead"
                      :label="userData.is_future_lead ? 'Yes': 'No'"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  Bank Details Lock
                  <v-switch
                      v-model="userData.is_lock"
                      :label="userData.is_lock ? 'Lock': 'Unlock'"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  Verify Email
                  <v-switch
                      v-model="userData.is_login_verified"
                      :label="userData.is_login_verified ? 'Verify': 'Not Verify'"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  Can Update Status
                  <v-switch
                      v-model="userData.can_update_status"
                      :label="userData.can_update_status ? 'Yes': 'No'"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  Can Update History Status
                  <v-switch
                      v-model="userData.can_update_history_status"
                      :label="userData.can_update_history_status ? 'Yes': 'No'"
                  ></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  Status
                  <v-switch
                      v-model="userData.is_active"
                      :label="userData.is_active ? 'Active': 'Inactive'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn
                class="btn btn-primary"
                @click="saveUserSetting()"
                :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog max-width="400" persistent v-model="subscription_dialog">
        <v-card>
          <v-card-title>Manage Subscription Date</v-card-title>
          <v-card-text>
            <v-col cols="12">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="plan_expiry_date"
                      label="Plan Expiry Date"
                      readonly
                      clearable
                      autofocus
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="plan_expiry_date"
                    outlined
                    dense
                    no-title
                    @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="btn btn-standard" depressed @click="subscription_dialog=false">Close</v-btn>
            <v-btn class="btn btn-primary text-white" depressed @click="updateExpiryDate">Update Expiry Date</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-app>
</template>

<script>
import UserSettingService from "@/services/user/setting/UserSettingService";
import UserService from "@/services/user/UserService";
import CountryService from "@/services/country/CountryService";
import BranchService from "@/services/branch/BranchService";
import {required, requiredIf} from "vuelidate/lib/validators";

const userSetting = new UserSettingService();
const country = new CountryService();
const userService = new UserService();

export default {
  name: "UserDetails",
  props: ['user_id', 'user', 'user_setting'],
  validations: {
    user_setting: {
      country_of_citizen_id: {required},
      first_language: {required},
      details: {
        required: requiredIf(function () {
          return this.user_setting.refused_visa;
        })
      }
    },
    userData: {
      first_name: {required},
      last_name: {required},
      gender: {required},
      mobile: {required},
    }
  },
  data() {
    return {
      subscription_dialog: false,
      dateExpiry: false,
      date: false,
      loading: false,
      dialog: false,
      countries: [],
      menuStartDate: false,
      plan_expiry_date: null,
      menu: false,
      menuDate: false,
      errors: [],
      userData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        mobile: '',
        work_phone: '',
        phone: '',
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'Source', '-', 'Save', 'NewPage', 'DocProps', 'Preview', 'Print', '-', 'Templates'],
        ]
      },
      valid_study_permits: [
        {text: 'UK Student Visa', value: 'uk_student_visa'},
        {text: 'USA F1 Visa', value: 'usa_f1'},
        {text: 'Canadian Study Permit or Visitor Visa', value: 'canadian_student'},
        {text: 'Australian Student Visa', value: 'australian_student_visa'},
      ]
    }
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    editUserSetting() {
      this.getAllCountries();
      this.userData = this.user;
      this.getAllBranches();
      this.showDialog();
      if(this.user_setting.first_language == null) {
        this.user_setting.first_language = ['Nepali'];
      }
      if(this.user_setting.country_of_citizen_id == null) {
        this.user_setting.country_of_citizen_id = 155;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.$reset();
      this.errors = [];
    },

    getAllCountries() {
      country
          .getAllCountry()
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    saveUserSetting() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        userService
            .update(this.user_id, this.userData)
            .then((response) => {

            })
            .catch((err) => {

            })
            .finally(() => {

            });

        userSetting
            .create(this.user_setting, this.user_id)
            .then((response) => {
              this.$emit('setting-saved');
              this.$emit('user-saved');
              this.$snotify.success('Detail saved successfully');
              this.closeDialog();
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.errors;
              this.$snotify.error('Oops looks like something went wrong');
            })
            .finally(() => {
              this.loading = false;
            })
      }
    },
    removeSubscription() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.user.plan_id = null;
            this.user.plan_expiry_date = null;
            let data = this.user;
            this.updateUser(data)
          }
        }
      });
    },
    updateUser(data) {
      userService
          .update(this.user_id, data)
          .then((response) => {
            this.$snotify.success("Request has been submitted successfully")
            this.subscription_dialog = false;
            this.$emit('user-saved')
          }).catch(error => {
        this.$snotify.error("Something went wrong please try again later")
      })
    },
    editSubscriptionDate() {
      this.subscription_dialog = true
      this.plan_expiry_date = this.user.plan_expiry_date
    },
    updateExpiryDate() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data = this.user;
            data.plan_expiry_date = this.plan_expiry_date;
            this.updateUser(data)
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
