<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card" >
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Agent Bank Detail
                </h4>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="dialog = true"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      v-if="checkIsAccessible('user-setting', 'create') || checkIsAccessible('user-setting', 'edit')"
                  >
                    {{user_setting && user_setting.bank_name ? 'Update' : 'Add'}} bank detail
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row v-if="user_setting && user_setting.bank_name">
              <v-col cols="12">
                <b>Bank Name: </b> {{user_setting.bank_name}}
              </v-col>
              <v-col cols="12">
                <b>Bank Address: </b> {{user_setting.bank_address}}
              </v-col>
              <v-col cols="12">
                <b>Account Name: </b> {{user_setting.account_name}}
              </v-col>
              <v-col cols="12">
                <b>Account Number: </b> {{user_setting.account_number}}
              </v-col>
              <v-col cols="12">
                <b>SWIFT Code: </b> {{user_setting.swift_code}}
              </v-col>
              <v-col cols="12">
                <b>Remarks: </b>
                <span v-html="user_setting.remarks"></span>
              </v-col>
            </v-row>
            <v-row class="text-center" v-else>
              <v-col class="text-center md:items-center md:justify-between py-12">
                  <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" alt="" style="margin: 0 auto"/>
                  <h5 class="mt-10">No Bank Detail Found</h5>
              </v-col>
            </v-row>
          </div>

        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Bank Detail</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="form-block">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user_setting.bank_name"
                      :error="$v.user_setting.bank_name.$error"
                      hide-details
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Bank Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user_setting.bank_name.$error"
                  >**This Field is Required</span
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user_setting.bank_address"
                      :error="$v.user_setting.bank_address.$error"
                      hide-details
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Bank Address <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user_setting.bank_address.$error"
                  >**This Field is Required</span
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user_setting.account_name"
                      :error="$v.user_setting.account_name.$error"
                      hide-details
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Account Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user_setting.account_name.$error"
                  >**This Field is Required</span
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user_setting.account_number"
                      :error="$v.user_setting.account_number.$error"
                      hide-details
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Account Number <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user_setting.account_number.$error"
                  >**This Field is Required</span
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user_setting.swift_code"
                      :error="$v.user_setting.swift_code.$error"
                      hide-details
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      SWIFT code <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user_setting.swift_code.$error"
                  >**This Field is Required</span
                  >
                </v-col>

                <v-col cols="12">
                  <b>Remarks: </b>
                  <ckeditor
                      :config="editorConfig"
                      v-model="user_setting.remarks"
                  ></ckeditor>
                </v-col>

              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :loading="loading"
              text
              @click="dialog = false"
          >Close</v-btn>

          <v-btn
              class="btn-primary"
              dark
              :loading="loading"
              @click.once="createOrUpdate"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import UserSettingService from "@/services/user/setting/UserSettingService";
import {required} from "vuelidate/lib/validators";

const userSetting = new UserSettingService();
export default {
  name: "BankDetail",
  props: ['user_id', 'user_setting'],
  data() {
    return {
      loading: false,
      dialog: false,
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ]
      },
    }
  },
  validations: {
    user_setting: {
      bank_name: {required},
      bank_address: {required},
      account_name: {required},
      account_number: {required},
      swift_code: {required}
    }
  },
  methods: {
    createOrUpdate() {
      this.loading = true;
      userSetting.create(this.user_id, this.user_setting).then(response => {
        this.$snotify.success("Update Successfully");
        this.dialog = false;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
