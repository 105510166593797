import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UserNotificationService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user-notification';
    }

    getAllByUser(data={},index = null, id) {
        let url = `${this.#api}/${id}/get-all-user`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    markAsViewed(notificationId){
        let url = `${this.#api}/${notificationId}/mark-as-viewed`;
        return apiService.get(url);
    }

    markAsNotViewed(notificationId){
        let url = `${this.#api}/${notificationId}/mark-as-not-viewed`;
        return apiService.get(url);
    }

    getAllNotification(data={},index = null, id) {
        let url = `${this.#api}/get/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getAdminNotificationsCount(adminUserId) {
        let url = `${this.#api}/${adminUserId}/count?is_not_viewed=0`;
        return apiService.get(url);
    }

    deleteNotification(id) {
        let url = `${this.#api}/${id}`;
        return apiService.post(url);
    }
}