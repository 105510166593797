import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UserPaymentService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user';
    }

    paginate(data = {}, index = null, userId = null) {
        let url = `${this.#api}/payment/all`;
        if(userId) {
            url = `${this.#api}/${userId}/payment`
        }
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(userId, data) {
        let url = `${this.#api}/${userId}/payment`;
        return apiService.post(url, data);
    }

    update(userId, id, data) {
        let url = `${this.#api}/${userId}/payment/${id}`;
        return apiService.put(url, data)
    }

    show(userId, id) {
        let url = `${this.#api}/${userId}/payment/${id}`
        return apiService.get(url)
    }

    delete(userId, id) {
        let url = `${this.#api}/${userId}/payment/${id}`
        return apiService.delete(url);
    }

    deletePayment(id) {
        let url = `${this.#api}/${id}/payment-delete`
        return apiService.post(url);
    }

    createInvoice(userId, id) {
        let url = `${this.#api}/${userId}/payment/${id}/create-invoice`
        return apiService.post(url);
    }
}