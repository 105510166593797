import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AgentCommissionService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/agent';
    }

    paginate(data = {}, index = null, agentId) {
        let url = `${this.#api}/${agentId}/commission`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(agentId, data) {
        let url = `${this.#api}/${agentId}/commission`;
        return apiService.post(url, data);
    }

    update(agentId, id, data) {
        let url = `${this.#api}/${agentId}/commission/${id}`;
        return apiService.put(url, data)
    }

    show(agentId, id) {
        let url = `${this.#api}/${agentId}/commission/${id}`
        return apiService.get(url)
    }

    delete(agentId, id) {
        let url = `${this.#api}/${agentId}/commission/${id}`
        return apiService.delete(url);
    }
}