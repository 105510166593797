<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="6">
          Invoice
        </v-col>
        <v-col cols="6">
          <v-btn
              class="btn mr-3 text-white btn-primary float-right"
              small
              @click="create"
          >
            <i class="fa fa-plus"></i>
            Add
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <invoice-component :invoices="invoices"></invoice-component>
        </v-col>
        <v-col cols="12" v-if="total>perPage">
          <b-pagination
              class="pull-right mt-7"
              @input="getInvoices"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
          ></b-pagination>
        </v-col>

      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
    <CreateAndUpdateApplicationPayment
        ref="create-update-payment"
    ></CreateAndUpdateApplicationPayment>
  </v-card>
</template>

<script>
import InvoiceService from "@/services/user/agent/invoice/InvoiceService";
import InvoiceComponent from "@/view/component/invoice/Invoice"
import CreateAndUpdateApplicationPayment from "@/view/pages/view/user/student/profile/components/payment/CreateAndUpdateApplicationPayment";

const invoiceService = new InvoiceService();

export default {
  name: "Invoice",
  props: ['user_id'],
  components: {
    InvoiceComponent,
    CreateAndUpdateApplicationPayment
  },
  data() {
    return {
      invoices: [],
      page: null,
      total: null,
      perPage: null,
    }
  },

  mounted() {
    this.getInvoices()
  },

  methods: {
    getInvoices() {
      let data = {user_id: this.user_id, limit: 5}
      invoiceService.paginate(data, this.page).then(response => {
        this.invoices = response.data.data
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch(error => {
        this.invoices = [];
      })
    },
    create() {
      this.$refs['create-update-payment'].createPayment(this.user_id);
    }
  }
}
</script>

<style scoped>

</style>
