<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Agent Document</span>
          <hr/>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <p>
                To continue submitting your application, please upload relevant documents to prove your identity.
              </p>
              <ul>
                <li>Business Registration</li>
                <li>Tax/PAN Registration Certificate</li>
                <li>Citizenship</li>
                <li>Passport</li>
              </ul>
            </v-col>
            <v-col cols="6">
              <v-file-input
                  :error="$v.document.doc_one.$error"
                  dense
                  label="Upload Document"
                  outlined
                  placeholder="Upload Document One"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  v-model="document.doc_one"
              >
                <template v-slot:label>
                  Upload document one (Mandatory) <span class="text-danger">*</span>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="6">
              <v-file-input
                  :error="$v.document.doc_two.$error"
                  dense
                  label="Upload Document"
                  outlined
                  placeholder="Upload Document"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  v-model="document.doc_two"
              >
                <template v-slot:label>
                  Upload document two (Mandatory) <span class="text-danger">*</span>
                </template>
              </v-file-input>

            </v-col>
            <v-col cols="6">
              <v-file-input
                  dense
                  label="Upload Document"
                  outlined
                  placeholder="Upload Document"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  v-model="document.doc_three"
              ></v-file-input>
            </v-col>
            <v-col cols="6">
              <v-file-input
                  dense
                  label="Upload Document"
                  outlined
                  placeholder="Upload Document"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  v-model="document.doc_four"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
           class="btn btn-primary"
           @click="submitDocuments()"
           :loading="loading"
        >
          Update
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserSettingService from "@/services/user/setting/UserSettingService";

const userSetting = new UserSettingService();
export default {
  name: "CreateDocument",
  props: ['user_setting'],
  data() {
    return {
      dialog: false,
      loading: false,
      document: {
        doc_one: null,
        doc_two: null,
        doc_three: null,
        doc_four: null,
      }
    }
  },
  validations: {
    document: {
      doc_one: {required},
      doc_two: {required},
    }
  },
  computed: {
    agentId() {
      return this.$route.params.id;
    }
  },
  methods: {

    closeDialog() {
      this.dialog = false;
      this.document = {
        doc_one: null,
        doc_two: null,
        doc_three: null,
        doc_four: null,
      }
    },

    openDialog() {
      this.dialog = true;
    },

    submitDocuments() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 5000);
      } else {
        this.loading = true;
        let formData = new FormData();
        if (this.document.doc_one != null && this.document.doc_one != undefined) {
          formData.append('doc_one', this.document.doc_one);
        }

        if (this.document.doc_two != null && this.document.doc_two != undefined) {
          formData.append('doc_two', this.document.doc_two);
        }

        if (this.document.doc_three != null && this.document.doc_three != undefined) {
          formData.append('doc_three', this.document.doc_three);
        }

        if (this.document.doc_four != null && this.document.doc_four != undefined) {
          formData.append('doc_four', this.document.doc_four);
        }

        formData.append('user_id', this.agentId);
        formData.append('send_email', 1);

        if(this.agentId) {
          userSetting
              .uploadVerificationDocument(formData, this.agentId)
              .then(() => {
                this.$snotify.success('Document uploaded successfully');
                this.closeDialog();
                this.$emit('refresh');
                this.loading = false;
              })
              .catch((err) => {

                this.$snotify.error('Oops looks like something went wrong.');
              });
        }
        this.loading = false;
      }
    },
  }
}
</script>