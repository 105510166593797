import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ApplicationDiscussionService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/application-discussion';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    closeTicket(id, data) {
        let url = `${this.#api}/${id}/close-discussion`
        return apiService.post(url, data)
    }

    markAsUnReadTicket(data) {
        let url = `${this.#api}/mark-as-unread-discussion`
        return apiService.post(url, data)
    }

    markAllReadTicket() {
        let url = `${this.#api}/mark-all-read-discussion`
        return apiService.post(url)
    }

    multipleUpdate(data) {
        let url = `${this.#api}/multiple-update`
        return apiService.post(url, data)
    }

    getAllCloseByUser(userId) {
        let url = `${this.#api}/${userId}/closed`
        return apiService.get(url)
    }

    getAllDiscussionClosed(data, index) {
        let url = `${this.#api}/get/all-closed`
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}