import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class UserSettingService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user';
    }

    index(userId) {
        let url = `${this.#api}/${userId}/setting`;
        return apiService.get(url);
    }

    create(data, userId) {
        let url = `${this.#api}/${userId}/setting`;
        return apiService.post(url, data)
    }

    delete(userId) {
        let url = `${this.#api}/${userId}`
        return apiService.delete(url)
    }
}