<template>
  <v-app>
    <v-card raised>
      <v-card-title>
        Applications
        <span class="badge badge-primary">{{ total }}</span>
        <v-spacer></v-spacer>

      </v-card-title>

      <v-card-text>
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="search.reference_id" outlined dense v-on:keyup.enter="searchApplication"
                            label="Search by application no" clearable></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="search.applicant_name" outlined dense v-on:keyup.enter="searchApplication"
                            label="Search by applicant name" clearable></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-autocomplete :items="institutions" item-text="title" item-value="id" v-model="search.institution_id"
                        outlined dense v-on:keyup.enter="searchApplication" clearable
                        label="Institution"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-autocomplete :items="education_levels" item-text="title" item-value="id" v-on:keyup.enter="searchApplication"
                        v-model="search.education_level_id" outlined dense clearable
                        label="Education Level"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-autocomplete :items="programSessions" item-text="title" item-value="id" v-on:keyup.enter="searchApplication"
                        v-model="search.academic_program_session_id" outlined dense clearable
                        label="Program session"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-autocomplete :items="visaApplicationStatus" item-text="title" item-value="id" v-on:keyup.enter="searchApplication"
                        v-model="search.application_status_state_id" outlined dense clearable
                        label="Visa Application Status"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="text-right">
              <v-btn class="btn btn-primary text-white" depressed @click="searchApplication">Search</v-btn>
            </v-col>
          </v-row>
        </div>

        <v-expansion-panels>
          <v-expansion-panel
              :key="index" focusable
              v-for="(item, index) in applications"
              v-if="applications && applications.length != 0"
          >
            <v-expansion-panel-header v-if="item.application_type =='academic'" @click="getStatusHistories(item.id)"
            >
              <div>
                {{ index + 1 }}. {{ item.institution }} - {{ item.academic_program }}
                <span class="font-weight-bold"> <a @click="openStudent(item.applicant_id)">{{item.applicant}}</a></span>
                <span class="badge" v-bind:class="{ 'badge-success': item.is_enrolment_confirmed, 'badge-danger': !item.is_enrolment_confirmed }">
                  {{ item.is_enrolment_confirmed ? "Paid" : "Pending" }}
                </span>
                <b class="ml-2"> Ref num: <a @click="openApplicationDetail(item.id)">{{item.reference_id}}</a></b>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-header v-else
                                      @click="getStatusHistories(item.id)"
            >
              {{ item.application_type_title }} - {{ item.application_permit }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-timeline
                      clipped
                      dense
                      v-if="histories.length"
                  >
                    <v-timeline-item

                        :key="index"
                        class="mb-4"
                        color="grey"
                        icon-color="grey lighten-2"
                        small
                        v-for="(history, index) in histories"
                    >
                      <v-row justify="space-between">
                        <v-col cols="8">
                          <v-chip
                              class="white--text ml-0"
                              color="purple"
                              label
                              small
                          >
                            {{ history.visa_status }}
                          </v-chip>
                          <v-btn
                              class="ml-2"
                              icon
                              small
                              @click="deleteHistory(history.id, item.id)"
                              color="dark-blue"
                          >
                            <v-icon dark>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                            class="text-right"
                            cols="4"
                        >
                          {{ history.created_date }} {{ history.created_time }}
                        </v-col>
                      </v-row>
                    </v-timeline-item>

                  </v-timeline>
                  <strong v-else>
                    Process Not Started Yet
                  </strong>
                </v-col>
                <v-col cols="6" v-if="item.application_type == 'academic'">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>College/University</v-list-item-title>
                      <v-list-item-subtitle>{{ item.institution ? item.institution : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Academic Program</v-list-item-title>
                      <v-list-item-subtitle>{{
                          item.academic_program ? item.academic_program :
                              'N/A'
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Country</v-list-item-title>
                      <v-list-item-subtitle>{{ item.country_title ? item.country_title : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Level</v-list-item-title>
                      <v-list-item-subtitle>{{ item.level ? item.level : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col v-else>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Visa Application Date</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.formatted_visa_application_date ? item.formatted_visa_application_date : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Visa Medical Date</v-list-item-title>
                      <v-list-item-subtitle>{{ item.formatted_medical_date ? item.formatted_medical_date : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Visa Medical Date</v-list-item-title>
                      <v-list-item-subtitle>{{ item.formatted_biometric_date ? item.formatted_biometric_date : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Co contact date</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.formatted_co_latest_contact_date ? item.formatted_co_latest_contact_date : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Visa Decision Date</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.formatted_visa_decision_date ? item.formatted_visa_decision_date : 'N/A' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" class="text-right">
                  <div>
                    <v-btn @click="updateStatus(item)" small class="btn btn-primary ml-2" dark>
                      <i class="fa fa-edit"></i> Update Status
                    </v-btn>

                    <v-btn @click="updateApplication(item)" small class="btn btn-primary ml-2" dark>
                      <i class="fa fa-edit"></i> Update Application
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <application-discussion ref="application-discussion"
                                          :applicationId="item.id"></application-discussion>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div v-if="applications.length == 0">
          <v-col class="text-center md:items-center md:justify-between py-12">
            <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"/>
            <p>No processing has started for this user.</p>
<!--            <v-btn class="btn btn-secondary">-->
<!--              Start processing-->
<!--            </v-btn>-->
          </v-col>
        </div>

        <b-pagination
            v-if="applications.length > 0"
            class="pull-right mt-7"
            @input="getVisaApplications"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
        ></b-pagination>
      </v-card-text>
    </v-card>

    <update-application-history
        @refresh="getStatusHistories"
        ref="update-application-history"
    ></update-application-history>
  </v-app>
</template>

<script>
import ApplicationService from "@/services/application/ApplicationService";
import ApplicationDiscussion from "@/view/pages/view/user/student/profile/components/application-discussion/ApplicationDiscussion";
import UpdateApplicationHistory from "@/view/pages/view/user/student/profile/components/application/UpdateApplicationHistory.vue";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";
import VisaApplicationStatusStateService from "@/services/visa/application-status/VisaApplicationStatusStateService";

const institutionService = new InstitutionService();
const educationLevelService = new EducationLevelService();
const programSessionService = new AcademicProgramSessionService();
const visaApplicationStatusState = new VisaApplicationStatusStateService();
const applicationService = new ApplicationService();
export default {
  name: "Application",
  props: ["user_id"],
  components: {
    ApplicationDiscussion,
    UpdateApplicationHistory
  },
  data() {
    return {
      applications: [],
      histories: [],
      visaApplicationStatus: [],
      institutions: [],
      education_levels: [],
      programSessions: [],
      loading: true,
      page: 1,
      total: null,
      perPage: null,
      search: {
        reference_id: '',
        agent_name: '',
        applicant_name: '',
        institution_id: '',
        education_level_id: '',
        is_opened: '',
        is_draft: '',
        is_valid_offer: '',
        is_complete: '',
        is_deferred: '',
        is_refund: '',
        is_archived: '0',
        application_status: '',
        application_status_state_id: '',
      },
    }
  },
  mounted() {
    this.getVisaApplications();
    this.getEducationLevel();
    this.getInstitution();
    this.getAcademicProgramSession();
    this.getVisaApplicationStatus();
  },

  methods: {

    searchApplication() {
      this.page = 1;
      this.getVisaApplications();
    },

    openStudent(studentId) {
      console.log(studentId)
      this.$router.push({ name: 'student-profile', params: { id: studentId } });
    },

    openApplicationDetail(application_id) {
      this.$router.push({ name: 'application-detail', params: { id: application_id } });
    },

    getVisaApplicationStatus() {
      visaApplicationStatusState.paginate({application_status_id: 4}).then(response => {
        this.visaApplicationStatus = response.data.data;
      }).catch(() => {});
    },

    getInstitution() {
      institutionService.getByCountry(39).then(response => {
        this.institutions = response.data.institutions;
      }).catch(() => {});
    },

    getEducationLevel() {
      educationLevelService.all().then(response => {
        this.education_levels = response.data.educationLevels;
      }).catch(() => {});
    },

    getAcademicProgramSession() {
      programSessionService.all().then(response => {
        this.programSessions = response.data.academicProgramSessions;
      }).catch(() => {});
    },

    getVisaApplications() {
      applicationService
          .paginateByAgent(this.search, this.page, this.user_id)
          .then((response) => {
            this.applications = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          }).catch(error => {
        this.applications = []
      })
    },

    getStatusHistories(applicationId) {
      applicationService
          .getHistories(applicationId)
          .then((response) => {
            this.histories = response.data.data;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },

    updateStatus(application) {
      this.$refs['update-application-history'].updateApplicationHistory(application);
    },

    updateApplication(item) {
      this.$tabs.close().then(response => {
        this.$router.push({ name: 'application-detail', params: { id: item.id } })
      })
    },

    closeApplicationDialog() {
      this.application_dialog = false;
      this.application = {}
      this.getVisaApplications()
    },

    deleteHistory(historyId, applicationId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            applicationHistory
                .delete(historyId)
                .then((response) => {
                  // this.getVisaApplications();
                  this.$emit("update-list");
                  this.getStatusHistories(applicationId);
                  this.$snotify.success("Status Deleted Successfully.");
                })
                .catch((err) => {
                });
          }
        },
      });
    },
  }

}
</script>

<style scoped>

</style>