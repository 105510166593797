<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Application Payment</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="application_payment.title"
                  outlined
                  dense
                  :error="$v.application_payment.title.$error"
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.title">{{ errors.title[0] }}</span>
              <span class="text-danger" v-if="$v.application_payment.title.$error">This Field is required</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-model="application_payment.status"
                  outlined
                  dense
                  :items="statuses"
                  item-text="title"
                  item-value="value"
                  :error="$v.application_payment.status.$error"
              >
                <template v-slot:label>
                  Status <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.status">{{ errors.status[0] }}</span>
              <span class="text-danger" v-if="$v.application_payment.status.$error">This Field is required</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-model="application_payment.service_id"
                  outlined
                  dense
                  :items="services"
                  item-text="title"
                  item-value="id"
              >
                <template v-slot:label>
                  Service
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="application_payment.amount"
                  outlined
                  type="number"
                  dense
                  :error="$v.application_payment.amount.$error"
              >
                <template v-slot:label>
                  Amount <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.amount">{{ errors.amount[0] }}</span>
              <span class="text-danger" v-if="$v.application_payment.amount.$error">This Field is required</span>
            </v-col>
            <!--            <pre>{{ currencies }}</pre>-->
            <v-col cols="12" md="6">
              <v-select
                  v-model="application_payment.currency_id"
                  outlined
                  dense
                  :items="currencies"
                  :item-text="item=>`${item.currency}(${item.symbol})`"
                  item-value="id"
                  :error="$v.application_payment.currency_id.$error"
              >
                <template v-slot:label>

                </template>
                <template v-slot:label>
                  Currency <span class="text-danger">*</span>
                </template>
              </v-select>
              <!--              <span class="text-danger" v-if="errors.currency">{{ errors.currency[0] }}</span>-->
              <span class="text-danger" v-if="$v.application_payment.currency_id.$error">This Field is required</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="application_payment.payment_method"
                  outlined
                  dense
                  :items="payment_methods"
                  item-text="text"
                  item-value="value"
                  :error="$v.application_payment.payment_method.$error">
                <template v-slot:label>

                </template>
                <template v-slot:label>
                  Payment method <span class="text-danger">*</span>
                </template>
              </v-select>

              <span class="text-danger" v-if="$v.application_payment.payment_method.$error">This Field is required</span>
            </v-col>
            <v-col cols="12" md="12">
              Remarks <span class="text-danger">(100 words limited) Words Count: {{wordCount}}</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="application_payment.remarks"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.application_payment.remarks.$error">This Field is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          {{ edit ? "Update" : "Save" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import UserPaymentService from "@/services/user/payment/UserPaymentService";
import ServiceService from "@/services/service/ServiceService";
import {required} from "vuelidate/lib/validators";
import CountryService from "@/services/country/CountryService";

const paymentService = new UserPaymentService();
const service = new ServiceService();
const countryService = new CountryService();
export default {
  validations: {
    application_payment: {
      title: {required},
      amount: {required},
      payment_date: {},
      currency_id: {required},
      status: {required},
      payment_method: {required},
      remarks: {required},
    }
  },
  data() {
    return {
      title: "",
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      application_payment: {
        title: '',
        user_id: null,
        agent_id: null,
        service_id: null,
        admin_user_id: null,
        amount: '',
        payment_date: '',
        currency_id: '',
        status: 'pending',
        user_type:'student',
        payment_method:'bank',
        remarks:'',
        is_subscribed: false
      },
      services: [],
      menuStartDate: false,
      currencies: [],
      statuses: [
        {"title": "Pending", value: 'pending'},
        {"title": "Paid", value: 'paid'},
      ],
      payment_methods:[
        {text:'Bank',value:'bank'},
        {text:'Other',value:'other'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'Image']],
      },
    }
  },
  computed: {
    wordCount() {
      if(this.application_payment.remarks) {
        let count = this.application_payment.remarks.split(" ");
        if (count.length > 100) {
          this.$snotify.error("Words should be 100")
        }
        return count.length;
      }
      return 0;
    }
  },
  mounted() {
    this.getServices();
    this.getCurrencies();
  },
  methods: {
    getCurrencies() {
      countryService.getSelectedCurrencies().then(response => {
        this.currencies = response.data.currencies
      })
    },
    createPayment(agent_id, application_id, user_id = null) {
      if(application_id) {
        this.application_payment.application_id = application_id;
        this.application_payment.user_type = 'application';
      }
      if(agent_id) {
        this.application_payment.agent_id = agent_id;
        this.application_payment.user_type = 'agent';
      }
      if(user_id) {
        this.application_payment.user_id = user_id;
        this.application_payment.user_type = 'student';
      }
      this.title = 'Add';
      this.edit = false;
      this.dialog = true;
    },
    editPayment(application_payment) {
      this.application_payment = application_payment;
      this.title = 'Edit';
      this.edit = true;
      this.dialog = true;
    },
    resetForm() {
      this.application_payment = {
        title: '',
        user_id: null,
        agent_id: null,
        service_id: null,
        admin_user_id: null,
        amount: '',
        payment_date: '',
        currency: '',
        status: 'pending',
        user_type:'student',
        payment_method:'bank',
      };
      this.errors = [];
      this.$v.$reset();
    },
    closeDialog() {
      this.resetForm();
      this.dialog = false;
    },
    createOrUpdate() {
      this.application_payment.admin_user_id = this.currentUser.id;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        let userId = null;
        if(this.application_payment.user_type == 'student') {
          userId = this.application_payment.user_id;
        } else {
          userId = this.application_payment.agent_id;
        }
        if (!this.edit) this._create(userId);
        else this.update(userId);
      }
    },
    _create(user_id) {
      this.loading = true;
      paymentService.create(user_id, this.application_payment)
          .then((response) => {
            this.$snotify.success('Payment saved successfully');
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
          });
    },
    update(user_id) {
      this.loading = true;
      paymentService.update(user_id, this.application_payment.id, this.application_payment)
          .then(response => {
            this.$snotify.success("Payment updated successfully");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getServices() {
      service
          .all()
          .then((response) => {
            this.services = response.data.services;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    }
  }
}
</script>
