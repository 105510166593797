<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} agent commission</span>
          <hr/>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                  v-model="agent_commission.institution_id"
                  :items="institutions"
                  item-text="title"
                  item-value="id"
                  :error="$v.agent_commission.institution_id.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Institution <span class="text-danger">*</span>
                </template>
              </v-select
              >
              <span class="text-danger" v-if="$v.agent_commission.institution_id.$error"
              >**This Field is Required</span
              >
            </v-col>

            <v-col cols="12">
              <v-select
                  label="Education Levels"
                  :items="educationLevels"
                  item-text="title"
                  item-value="id"
                  multiple
                  small-chips
                  deletable-chips
                  v-model="agent_commission.education_level_ids"
                  outlined
                  dense
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                  v-model="agent_commission.commission"
                  :error="$v.agent_commission.commission.$error"
                  hide-details
                  outlined
                  dense
              >
                <template v-slot:label>
                  Commission <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.agent_commission.commission.$error"
              >**This Field is Required</span
              >
            </v-col>

            <v-col cols="12">
              <label>Remarks <span class="text-danger">*</span></label>
              <ckeditor
                  v-model="agent_commission.remarks"
                  :config="editorConfig"
              >
              </ckeditor>
            </v-col>

            <v-col cols="12" md="4">
              <v-switch
                  v-model="agent_commission.is_active"
                  label="Status"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            v-if="checkIsAccessible('agent-commission', 'create') || checkIsAccessible('agent-commission', 'edit')"
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          {{edit ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import AgentCommissionService from "@/services/user/agent/commission/AgentCommissionService";
import AgentLimitInstitutionService from "@/services/user/agent/limit-institution/AgentLimitInstitutionService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";

const agentCommission = new AgentCommissionService();
const educationLevel = new EducationLevelService();
const agentLimitInstitution = new AgentLimitInstitutionService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      institutionIds: [],
      institutions: [],
      educationLevels: [],
      agent_commission: {
        institution_id: '',
        education_level_ids: '',
        commission: '',
        remarks: '',
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ]
      },
    }
  },
  validations: {
    agent_commission: {
      institution_id: {required},
      commission: {required},
      remarks: {required},
    }
  },

  computed: {

    agentId() {
      return this.$route.params.id;
    },

  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },

    createAgentCommission(institutionIds) {
      this.dialog = true;
      this.edit = false;
      this.institutionIds = institutionIds;
      this.title = 'Add';
      this.getAllInstitution();
      this.getAllEducationLevel();
    },

    editAgentCommission(item) {
      this.dialog = true;
      this.edit = true;
      this.title = 'Edit';
      this.agent_commission = item;
      this.getAllInstitution();
      this.getAllEducationLevel();
    },

    getAllInstitution() {
      agentLimitInstitution.getAgentLimitInstitution(this.agentId).then(response => {
        this.institutions = response.data.institutions;
        // let institutions = this.institutions;
        // if(institutions.length > 0) {
        //   institutions.forEach((item, index) => {
        //     if(this.institutionIds.includes(item.id)) {
        //       this.institutions.splice(index, 1);
        //     }
        //     console.log(index)
        //
        //   })
        // }
      }).catch(() => {})
    },

    getAllEducationLevel() {
      educationLevel.all().then(response => {
        this.educationLevels = response.data.educationLevels;
      }).catch(() => {})
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },

    _create: function () {
      this.loading = true;
      agentCommission
          .create(this.agentId, this.agent_commission)
          .then(response => {
            this.$snotify.success(`Created Successfully`);
            this.errors = [];
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch(() => {
            this.loading = false;
          })
    },

    update: function () {
      this.loading = true;
      agentCommission
          .update(this.agentId, this.agent_commission.id, this.agent_commission)
          .then(response => {
            this.$snotify.success(`Update Successfully`);
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch(() => {
            this.loading = false;
          })
    },

    resetForm() {
      this.$v.$reset();
      this.agent_commission = {
        institution_id: '',
        education_level_ids: '',
        commission: '',
        remarks: '',
        is_active: true,
      };
    },
  },
}
</script>
