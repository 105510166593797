<template>
    <v-app>
        <v-list two-line>
            <v-list-item class="d-flex justify-space-between">
                <v-list-item-title>
                    <h4> User Profile </h4>
                </v-list-item-title>
                <div class="d-flex">
                    <v-btn
                        class="btn mr-3 text-white btn-primary"
                        small
                        @click="editUserSetting"
                    >
                        <i class="fa fa-edit"></i>
                        Change Details
                    </v-btn>
                </div>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-phone
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user.full_name ? user.full_name : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Full Name</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <v-icon>mdi-message-text</v-icon>
                </v-list-item-icon>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-flag
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user.gender_text ? user.gender_text : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Gender</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-calendar
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user.date_of_birth ? user.formatted_date_of_birth : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>DOB</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-email
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user.email ? user.email : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-git
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user.branch_name ? user.branch_name : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Branch</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-phone
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user.phone ? user.phone : ''}} {{user.mobile ? user.mobile : ''}}</v-list-item-title>
                    <v-list-item-subtitle>Phone</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-map-marker
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Mailing Address as Per Passport</v-list-item-title>
                    <v-list-item-subtitle>{{user_setting.permanent_address ? user_setting.permanent_address : 'N/A'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-flag
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Citizen of</v-list-item-title>
                    <v-list-item-subtitle>{{user_setting.citizen_of ? user_setting.citizen_of : 'N/A'}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="user_setting.first_language != 0">
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-flag
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        class="mr-2"
                        small
                        color="primary"
                        v-for="(language, index) in user_setting.first_language" :key="index">
                        {{ language ? language : 'N/A'}}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>as first language</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <v-icon>mdi-message-text</v-icon>
                </v-list-item-icon>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-passport
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{user_setting.passport_number ? user_setting.passport_number : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Passport Number</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-star
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{user_setting.passport_expiry_date ? user_setting.passport_expiry_date : 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Passport Expiry Date</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-heart
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                    Married ?
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span
                            class="badge"
                            :class="user_setting.is_married ? 'badge-success' : 'badge-danger' "
                        >
                            {{user_setting.is_married ? 'Yes' : 'No'}}
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>


            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-passport
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                    Valid study permit ?
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{user_setting.valid_study_permit_text ? user_setting.valid_study_permit_text : 'N/A'}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-passport
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                    Refused Visa ?
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span
                            class="badge"
                            :class="user_setting.refused_visa ? 'badge-success' : 'badge-danger' "
                        >
                            {{user_setting.refused_visa ? 'Yes' : 'No'}}
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-heart
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                    Deceased ?
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span
                            class="badge"
                            :class="user.is_deceased ? 'badge-success' : 'badge-danger' "
                        >
                            {{user.is_deceased ? 'Yes' : 'No'}}
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-heart
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                    Status ?
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span
                            class="badge"
                            :class="user.is_active ? 'badge-success' : 'badge-danger' "
                        >
                            {{user.is_active ? 'Active' : 'Inactive'}}
                        </span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="user_setting.details">
                <v-list-item-icon>
                    <v-icon color="indigo">
                        mdi-map-marker
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <p v-html="user_setting.details"></p>
                </v-list-item-content>
            </v-list-item>

            <!-- user setting models -->
            <v-dialog
                v-model="dialog"
                max-width="800"
                scrollable
                persistent
            >
                <v-card>
                    <v-toolbar dark>
                        <v-card-title class="text-h5">
                            <span>Update Student Details</span>
                            <hr>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="closeDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="userData.first_name"
                                        outlined
                                        dense
                                        :error="$v.userData.first_name.$error"
                                    >
                                        <template v-slot:label>
                                            First Name <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.userData.first_name.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.first_name" >* {{ errors.first_name[0] }}</span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="userData.middle_name"
                                        outlined
                                        dense
                                        :error="$v.userData.middle_name.$error"
                                    >
                                        <template v-slot:label>
                                            Middle Name
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.userData.middle_name.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.middle_name" >* {{ errors.middle_name[0] }}</span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="userData.last_name"
                                        outlined
                                        dense
                                        :error="$v.userData.last_name.$error"
                                    >
                                        <template v-slot:label>
                                            Last Name <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.userData.last_name.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.last_name" >* {{ errors.last_name[0] }}</span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <label for="">Gender <span class="text-danger">*</span></label>
                                    <v-radio-group
                                        v-model="userData.gender"
                                        row
                                        >
                                        <v-radio
                                            label="Male"
                                            value="male"
                                        ></v-radio>
                                        <v-radio
                                            label="Female"
                                            value="female"
                                        ></v-radio>
                                        <v-radio
                                            label="Prefer Not to Say"
                                            value="prefer_not_to_say"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>

                                <v-col cols="12" md="4">
                                    Status
                                    <v-switch
                                        v-model="userData.is_active"
                                        :error="$v.userData.is_active.$error"
                                        :label="userData.is_active ? 'Active': 'Inactive'"
                                    ></v-switch>
                                    <span class="text-danger" v-if="$v.userData.is_active.$error">This Field is required</span>
                                    <span class="text-danger" v-if="errors.is_active">{{ errors.is_active[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="4">
                                    Deceased
                                    <v-switch
                                        v-model="userData.is_deceased"
                                        :error="$v.userData.is_deceased.$error"
                                        :label="userData.is_deceased ? 'Yes': 'No'"
                                    ></v-switch>
                                    <span class="text-danger" v-if="$v.userData.is_deceased.$error">This Field is required</span>
                                    <span class="text-danger" v-if="errors.is_deceased">{{ errors.is_active[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        ref="menuDate"
                                        v-model="menuDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="userData.date_of_birth"
                                            label="DOB"
                                            readonly
                                            outlined
                                            dense
                                            :error="$v.userData.date_of_birth.$error"
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="userData.date_of_birth"
                                            outlined
                                            dense
                                            no-title
                                            @input="menuDate = false"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                    <span class="text-danger" v-if="$v.userData.date_of_birth.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.date_of_birth" >* {{ errors.date_of_birth[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-select
                                        :items="ourBranches"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        label="Branch"
                                        v-model="userData.branch_id"
                                    ></v-select>
                                    <span class="text-danger" v-if="$v.userData.branch_id.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.branch_id" >* {{ errors.branch_id[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="userData.mobile"
                                        outlined
                                        :error="$v.userData.mobile.$error"
                                        dense
                                    >
                                        <template v-slot:label>
                                            Mobile
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.userData.mobile.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.mobile" >* {{ errors.mobile[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="userData.phone"
                                        outlined
                                        :error="$v.userData.phone.$error"
                                        dense
                                    >
                                        <template v-slot:label>
                                            Phone
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.userData.phone.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.phone" >* {{ errors.phone[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="userData.work_phone"
                                        outlined
                                        :error="$v.userData.work_phone.$error"
                                        dense
                                    >
                                        <template v-slot:label>
                                            Work Phone
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.userData.work_phone.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.work_phone" >* {{ errors.work_phone[0] }}</span>
                                </v-col>

                              <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="date"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="userData.visa_expired_date"
                                        label="Visa expired date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        clearable
                                    >
                                      <template v-slot:label>
                                        Visa expired date
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                      no-title
                                      v-model="userData.visa_expired_date"
                                      @input="date = false"
                                  ></v-date-picker>
                                </v-menu>

                              </v-col>

                              <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="dateExpiry"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="userData.end_date_program"
                                        label="End date program"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                    >
                                      <template v-slot:label>
                                        End date program
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                      no-title
                                      v-model="userData.end_date_program"
                                      @input="dateExpiry = false"
                                  ></v-date-picker>
                                </v-menu>

                              </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        :items="countries"
                                        item-text="title"
                                        item-value="id"
                                        v-model="user_setting.country_of_citizen_id"
                                        outlined
                                        :error="$v.user_setting.country_of_citizen_id.$error"
                                        dense
                                    >
                                        <template v-slot:label>
                                        Country
                                        </template>
                                    </v-autocomplete>
                                    <span class="text-danger" v-if="$v.user_setting.country_of_citizen_id.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.country_of_citizen_id" >* {{ errors.country_of_citizen_id[0] }}</span>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-combobox
                                        v-model="user_setting.first_language"
                                        label="First Language"
                                        outlined
                                        :error="$v.user_setting.first_language.$error"
                                        small-chips
                                        multiple
                                        dense
                                    >
                                    </v-combobox>
                                    <span class="text-danger" v-if="$v.user_setting.first_language.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.first_language" >* {{ errors.first_language[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="user_setting.permanent_address"
                                        outlined
                                        :error="$v.user_setting.permanent_address.$error"
                                        dense
                                    >
                                        <template v-slot:label>
                                            Mailing Address as Per Passport <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.user_setting.permanent_address.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.permanent_address" >* {{ errors.permanent_address[0] }}</span>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="user_setting.passport_number"
                                        label="Passport Number"
                                        outlined
                                        :error="$v.user_setting.passport_number.$error"
                                        dense
                                    >
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.user_setting.passport_number.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.passport_number" >* {{ errors.passport_number[0] }}</span>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-menu
                                        ref="menuDate"
                                        v-model="menuStartDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="user_setting.passport_expiry_date"
                                            label="Passport Expiry Date"
                                            readonly
                                            outlined
                                            dense
                                            :error="$v.user_setting.passport_expiry_date.$error"
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="user_setting.passport_expiry_date"
                                            outlined
                                            dense
                                            no-title
                                            @input="menuStartDate = false"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                    <span class="text-danger" v-if="$v.user_setting.passport_expiry_date.$error">This field is required</span>
                                    <span class="text-danger" v-if="errors.passport_expiry_date" >* {{ errors.passport_expiry_date[0] }}</span>
                                </v-col>

                              <v-col cols="12" v-if="user_setting.refused_visa">
                                <label>Details <span class="text-danger">*</span> </label>
                                <ckeditor
                                    :config="editorConfig"
                                    v-model="user_setting.details"
                                >
                                </ckeditor>
                                <span class="text-danger" v-if="$v.user_setting.details.$error">This Field is required</span>
                                <span class="text-danger" v-if="errors.details" >* {{ errors.details[0] }}</span>
                              </v-col>

                              <v-col cols="12" md="3">
                                Future lead
                                <v-switch
                                    v-model="userData.is_future_lead"
                                    :label="userData.is_future_lead ? 'Yes': 'No'"
                                ></v-switch>
                              </v-col>

                              <v-col cols="12" md="3">
                                    Married ?
                                    <v-switch
                                        v-model="user_setting.is_married"
                                        :label="user_setting.is_married ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_setting.is_married.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.is_married">{{ errors.is_married[0] }}</div>
                                </v-col>
                                <v-col cols="12" md="3">
                                    Refused Visa ?
                                    <v-switch
                                        v-model="user_setting.refused_visa"
                                        :label="user_setting.refused_visa ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_setting.refused_visa.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.refused_visa">{{ errors.refused_visa[0] }}</div>
                                </v-col>
                                <v-col cols="12" md="6" v-if="!user_setting.refused_visa">
                                    <v-select
                                        outlined
                                        dense
                                        v-model="user_setting.valid_study_permit"
                                        :items="valid_study_permits"
                                        item-text="text"
                                        item-value="value"
                                    >
                                        <template v-slot:label>
                                            Do you have a valid Study Permit/Visa ?
                                            <span class="text-danger">*</span>
                                        </template>
                                    </v-select>
                                    <div class="text-danger" v-if="$v.user_setting.valid_study_permit.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.valid_study_permit">{{ errors.valid_study_permit[0] }}</div>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="mr-2" text dark medium
                            @click="closeDialog">
                        Cancel
                        </v-btn>
                      <v-btn
                          class="btn btn-primary"
                          @click="saveUserSetting()"
                          medium
                          :loading="loading"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-list>
    </v-app>
</template>

<script>
import UserSettingService from "@/services/user/setting/UserSettingService";
import UserService from "@/services/user/UserService";
import CountryService from "@/services/country/CountryService";
import BranchService from "@/services/branch/BranchService";
import { required, requiredIf } from "vuelidate/lib/validators";

const userSetting = new UserSettingService();
const country = new CountryService();
const userService = new UserService();

export default {
    name: "UserDetails",
    props: ['user_id', 'user', 'user_setting'],
    validations: {
        user_setting:{
          country_of_citizen_id: {required},
          first_language: {required},
          passport_number: {required},
          passport_expiry_date: {required},
          is_married: {},
          refused_visa: {},
          permanent_address: {required},
          valid_study_permit: {
            required: requiredIf(function () {
              return !this.user_setting.refused_visa;
            })
          },
          details: {required: requiredIf(function () {
              return this.user_setting.refused_visa;
            })
          }
        },
        userData:{
            first_name: {required},
            middle_name: {},
            last_name: {required},
            gender: {required},
            date_of_birth: {},
            mobile: {},
            work_phone: {},
            phone: {},
            branch_id: {},
            is_active: {},
            is_deceased: {}
        }
    },
    data(){
        return{
            dateExpiry: false,
            date: false,
            loading: false,
            dialog: false,
            countries: [],
            menuStartDate: false,
            menuDate: false,
            errors: [],
            userData:{
                first_name: '',
                middle_name: '',
                last_name: '',
                gender: '',
                date_of_birth: '',
                mobile: '',
                work_phone: '',
                phone: '',
            },
            editorConfig: {
              versionCheck: false,
              toolbar: [
                  [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
              ]
            },
            valid_study_permits:[
                {text: 'UK Student Visa', value:'uk_student_visa'},
                {text: 'USA F1 Visa', value:'usa_f1'},
                {text: 'Canadian Study Permit or Visitor Visa', value:'canadian_student'},
                {text: 'Australian Student Visa', value:'australian_student_visa'},
            ]
        }
    },
    methods:{
        showDialog(){
            this.dialog = true;
        },
        editUserSetting(){
            this.getAllCountries();
            this.userData = this.user;
            this.getAllBranches();
            this.showDialog();
        },
        closeDialog(){
            this.$emit('setting-saved');
            this.$emit('user-saved');
            this.dialog = false;
            this.resetForm();
        },
        resetForm(){
            this.$v.$reset();
            this.errors = [];
        },
        getAllCountries(){
            country
            .getAllCountry()
            .then((response) => {
                this.countries = response.data.data;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        saveUserSetting(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 3000);
            } else {
                this.loading = true;
                userService
                .update(this.user_id, this.userData)
                .then((response) => {

                })
                .catch((err) => {

                })
                .finally(() => {

                });

                userSetting
                .create(this.user_setting, this.user_id)
                .then((response) => {
                    this.$emit('setting-saved');
                    this.$snotify.success('Detail saved successfully');
                    this.closeDialog();
                })
                .catch((err) => {
                    this.loading = false;
                    this.errors = err.errors;
                    this.$snotify.error('Oops looks like something went wrong');
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
