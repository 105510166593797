<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Email Activities
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                  label="Email Subject"
                  v-model="search.title"
                  v-on:keyup.enter="searchEmailActivities"
                  @input="search.title = $event !== null ? $event : ''"
                  clearable
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-menu
                  ref="menuStartDate"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="search.date_sent"
                      label="Date Sent"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="search.date_sent"
                    outlined
                    dense
                    no-title
                    @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-btn
                  @click.prevent="searchEmailActivities"
                  class="btn btn-primary"

                  :loading="loading"
              >
                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-skeleton-loader
            type="table-thead"
            v-if="loading"
        >
        </v-skeleton-loader>

        <v-skeleton-loader
            v-if="loading"
            type="table-row-divider@25"
        >
        </v-skeleton-loader>

        <div class="contents__box contents__box--left w-100">
          <div v-if="activities.length > 0">
            <v-row class="mt-4 bg-gray-100" v-for="(item, index) in activities" :key="index">
              <v-col cols="12" md="4">
                <v-badge
                    :content="item.symbol_label"
                    :value="item.symbol_label"
                    inline
                    tile
                ></v-badge>
                <span>
                 {{ item.user }}
                </span>
              </v-col>
              <v-col cols="12" md="3">
                <span class="ms-0">
                 {{ item.title }}
                </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="ms-0">
                 {{ item.formatted_time }} <br>
                 {{ item.formatted_date_sent }}
                </span>
              </v-col>
              <v-col cols="12" md="1">
                <a :href="item.email_content_url" target="_blank" style="font-size:14px;" class="fw-bold">Preview</a>.
              </v-col>
            </v-row>
          </div>
          <b-pagination
              v-if="activities.length > 0"
              class="pull-right mt-7"
              @input="getAllAccountHolderActivities"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              :disabled="loading"
              last-number
          ></b-pagination>
          <div v-else class="text-center card-body">
            <span>No Data Found</span>
          </div>

          <ShowEmailActivity ref="show-email-activity"></ShowEmailActivity>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ShowEmailActivity from "./ShowEmailActivity";
import UserNotificationService from "@/services/user/notification/UserNotificationService";

const userNotification = new UserNotificationService();
export default {
  name: "EmailActivity",
  props: ['user_id'],
  components: {ShowEmailActivity},
  data() {
    return {
      activities: [],
      userId: "",
      total: null,
      perPage: null,
      page: null,
      loading: true,
      menu: false,
      search: {
        title: "",
        date_sent: ""
      }
    }
  },
  mounted() {
    this.getAllAccountHolderActivities();
  },
  methods: {
    getAllAccountHolderActivities() {
      userNotification.getAllByUser(this.search, this.pages, this.user_id).then(response => {
        this.activities = response.data.data;
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      })
    },
    showActivityDetail(activity) {
      this.$refs['show-email-activity'].showActivityDetail(activity);
    },
    searchEmailActivities() {
      this.getAllAccountHolderActivities();
    }
  }
}
</script>

<style lang="css">
.v-badge__badge {
  border-radius: 5px !important;
  width: 45px !important;
  height: 45px !important;
  font-size: x-large !important;
  padding: 10px 6px !important;
}
</style>